import { Authority } from '@/shared/security/authority';

const UserManagementComponent = () => import('@/admin/user-management/user-management.vue');
const UserManagementViewComponent = () => import('@/admin/user-management/user-management-view.vue');
const UserManagementEditComponent = () => import('@/admin/user-management/user-management-edit.vue');
const JhiDocsComponent = () => import('@/admin/docs/docs.vue');
const JhiConfigurationComponent = () => import('@/admin/configuration/configuration.vue');
const JhiHealthComponent = () => import('@/admin/health/health.vue');
const JhiLogsComponent = () => import('@/admin/logs/logs.vue');
const JhiMetricsComponent = () => import('@/admin/metrics/metrics.vue');
const Region = () => import('@/admin/region/region.vue');
const RegionUpdate = () => import('@/admin/region/region-update.vue');
const RegionDetails = () => import('@/admin/region/region-details.vue');
const City = () => import('@/admin/city/city.vue');
const CityUpdate = () => import('@/admin/city/city-update.vue');
const CityDetails = () => import('@/admin/city/city-details.vue');
const Municipality = () => import('@/admin/municipality/municipality.vue');
const MunicipalityUpdate = () => import('@/admin/municipality/municipality-update.vue');
const MunicipalityDetails = () => import('@/admin/municipality/municipality-details.vue');
const School = () => import('@/admin/school/school.vue');
const SchoolUpdate = () => import('@/admin/school/school-update.vue');
const SchoolDetails = () => import('@/admin/school/school-details.vue');
const SchoolYear = () => import('@/admin/school-year/school-year.vue');
const SchoolYearUpdate = () => import('@/admin/school-year/school-year-update.vue');
const SchoolYearDetails = () => import('@/admin/school-year/school-year-details.vue');
const ClassroomOption = () => import('@/admin/classroom-option/classroom-option.vue');
const ClassroomOptionUpdate = () => import('@/admin/classroom-option/classroom-option-update.vue');
const ClassroomOptionDetails = () => import('@/admin/classroom-option/classroom-option-details.vue');
const Subject = () => import('@/admin/subject/subject.vue');
const SubjectUpdate = () => import('@/admin/subject/subject-update.vue');
const SubjectDetails = () => import('@/admin/subject/subject-details.vue');
const Department = () => import('@/admin/department/department.vue');
const DepartmentUpdate = () => import('@/admin/department/department-update.vue');
const DepartmentDetails = () => import('@/admin/department/department-details.vue');

export default [
  {
    path: '/admin/region',
    name: 'Region',
    component: Region,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/region/new',
    name: 'RegionCreate',
    component: RegionUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/region/:regionId/edit',
    name: 'RegionEdit',
    component: RegionUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/region/:regionId/view',
    name: 'RegionView',
    component: RegionDetails,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/city',
    name: 'City',
    component: City,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/city/new',
    name: 'CityCreate',
    component: CityUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/city/:cityId/edit',
    name: 'CityEdit',
    component: CityUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/city/:cityId/view',
    name: 'CityView',
    component: CityDetails,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/municipality',
    name: 'Municipality',
    component: Municipality,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/municipality/new',
    name: 'MunicipalityCreate',
    component: MunicipalityUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/municipality/:municipalityId/edit',
    name: 'MunicipalityEdit',
    component: MunicipalityUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/municipality/:municipalityId/view',
    name: 'MunicipalityView',
    component: MunicipalityDetails,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/school',
    name: 'School',
    component: School,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/school/new',
    name: 'SchoolCreate',
    component: SchoolUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/school/:schoolId/edit',
    name: 'SchoolEdit',
    component: SchoolUpdate,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/school/:schoolId/view',
    name: 'SchoolView',
    component: SchoolDetails,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/user-management',
    name: 'User',
    component: UserManagementComponent,
    meta: { authorities: [Authority.SCHOOL, Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/new',
    name: 'UserCreate',
    component: UserManagementEditComponent,
    meta: { authorities: [Authority.SCHOOL, Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/edit',
    name: 'UserEdit',
    component: UserManagementEditComponent,
    meta: { authorities: [Authority.SCHOOL, Authority.ADMIN] },
  },
  {
    path: '/admin/user-management/:userId/view',
    name: 'UserView',
    component: UserManagementViewComponent,
    meta: { authorities: [Authority.SCHOOL, Authority.ADMIN] },
  },
  {
    path: '/admin/docs',
    name: 'JhiDocsComponent',
    component: JhiDocsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/health',
    name: 'JhiHealthComponent',
    component: JhiHealthComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/logs',
    name: 'JhiLogsComponent',
    component: JhiLogsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/metrics',
    name: 'JhiMetricsComponent',
    component: JhiMetricsComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/configuration',
    name: 'JhiConfigurationComponent',
    component: JhiConfigurationComponent,
    meta: { authorities: [Authority.ADMIN] },
  },
  {
    path: '/admin/school-year',
    name: 'SchoolYear',
    component: SchoolYear,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/school-year/new',
    name: 'SchoolYearCreate',
    component: SchoolYearUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/school-year/:schoolYearId/edit',
    name: 'SchoolYearEdit',
    component: SchoolYearUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/school-year/:schoolYearId/view',
    name: 'SchoolYearView',
    component: SchoolYearDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/classroom-option',
    name: 'ClassroomOption',
    component: ClassroomOption,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/classroom-option/new',
    name: 'ClassroomOptionCreate',
    component: ClassroomOptionUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/classroom-option/:classroomOptionId/edit',
    name: 'ClassroomOptionEdit',
    component: ClassroomOptionUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/classroom-option/:classroomOptionId/view',
    name: 'ClassroomOptionView',
    component: ClassroomOptionDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/admin/subject',
    name: 'Subject',
    component: Subject,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/admin/subject/new',
    name: 'SubjectCreate',
    component: SubjectUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/admin/subject/:subjectId/edit',
    name: 'SubjectEdit',
    component: SubjectUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/admin/subject/:subjectId/view',
    name: 'SubjectView',
    component: SubjectDetails,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/department',
    name: 'Department',
    component: Department,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/department/new',
    name: 'DepartmentCreate',
    component: DepartmentUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/department/:departmentId/edit',
    name: 'DepartmentEdit',
    component: DepartmentUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/department/:departmentId/view',
    name: 'DepartmentView',
    component: DepartmentDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
];
