import { getProfileImagePath } from '@/shared/helper';
import { User } from '@/shared/model/user.model';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class UserInfo extends Vue {
  @Prop({ default: () => new User() })
  readonly user: User;

  @Prop({ default: () => true })
  readonly showCycles: boolean;
  
  public get fullName(): string {
    return (this.user?.firstName ?? '') + ' ' + (this.user?.lastName ?? '');
  }

  public get imageSrc(): string {
    return getProfileImagePath(this.user.profileImage, this.user.sex);
  }
}