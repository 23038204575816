import BaseInput from '@/components/Inputs/BaseInput';
import BaseDropdown from '@/components/BaseDropdown';
import Card from '@/components/Cards/Card';
import Modal from '@/components/Modal';
import StatsCard from '@/components/Cards/StatsCard';
import BaseButton from '@/components/BaseButton';
import Badge from '@/components/Badge';
import BaseCheckbox from '@/components/Inputs/BaseCheckbox';
import BaseRadio from '@/components/Inputs/BaseRadio';
import BaseProgress from '@/components/BaseProgress';
import BasePagination from '@/components/BasePagination';
import BaseAlert from '@/components/BaseAlert';
import BaseNav from '@/components/Navbar/BaseNav';
import BaseHeader from '@/components/BaseHeader';
import SelectBox from '@/components/SelectBox';
import AddressBox from '@/components/Address/AddressBox';
import ContactInfo from '@/components/ContactInfo/ContactInfo';
import EntityAudit from '@/components/EntityAudit/EntityAudit';
import RouteBreadcrumb from '@/components/Breadcrumb/RouteBreadcrumb';
import UserProfile from '@/components/Profile/UserProfile';
import UserInfo from '@/components/Profile/UserInfo';
import CyclesList from '@/components/CycleList/CyclesList';
import PageWrapper from '@/core/page/app-page-wrapper';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { Input, Tooltip, Popover } from 'element-ui';
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install(Vue) {
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseHeader.name, BaseHeader);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(BaseNav.name, BaseNav);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(SelectBox.name, SelectBox);
    Vue.component(Card.name, Card);
    Vue.component(Modal.name, Modal);
    Vue.component(StatsCard.name, StatsCard);
    Vue.component(Input.name, Input);
    Vue.component('address-box', AddressBox);
    Vue.component('contact-info', ContactInfo);
    Vue.component('entity-audit', EntityAudit);
    Vue.component('route-breadcrumb', RouteBreadcrumb);
    Vue.component('user-profile', UserProfile);
    Vue.component('user-info', UserInfo);
    Vue.component('cycles-list', CyclesList);
    Vue.component('validation-provider', ValidationProvider);
    Vue.component('validation-observer', ValidationObserver);
    Vue.component('page-wrapper', PageWrapper);
    Vue.use(Tooltip);
    Vue.use(Popover);
  },
};

export default GlobalComponents;
