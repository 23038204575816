var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "user-picture" }, [
      _c("img", {
        staticClass: "rounded-circle img-fluid",
        staticStyle: { width: "50px" },
        attrs: { src: _vm.imageSrc, alt: "avatar" },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ml-3 mr-3" }, [
        _c("h4", { staticClass: "font-weight-bold" }, [
          _vm._v("\n        " + _vm._s(_vm.fullName) + "\n      "),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "contact font-normal text-gray-500" }, [
          _vm._v(
            "\n        " +
              _vm._s(_vm._f("uppercase")(_vm.user.login)) +
              "\n      "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _vm.showCycles
      ? _c(
          "div",
          [_c("cycles-list", { attrs: { cycles: _vm.user.cycles } })],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }