import { DEFAULT_COUNTRY_CODE } from '@/constants';
import { getMunicipalitiesByCityCode } from '@/shared/helper';
import { ICity } from '@/shared/model/city.model';
import { Contact } from '@/shared/model/contact.model';
import { IMunicipality } from '@/shared/model/municipality.model';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

const validations: any = {
  contact: {
    municipalityCode: {
      required,
    },
    cityCode: {
      required,
    },
    countryCode: {
      required,
    }
  }
};
@Component({
  validations,
})
export default class AddressBox extends Vue {
  @Prop({ default: () => new Contact() })
  readonly contact: Contact;

  public cities: ICity[] = [];
  public municipalities: IMunicipality[] = [];

  created(): void {
    this.cities = this.$store.getters.cities;
    this.municipalities = this.$store.getters.municipalities;
    if (this.contact.cityCode == null) {
      this.contact.cityCode = '';
    }
    if (this.contact.municipalityCode == null) {
      this.contact.municipalityCode = '';
    }
    this.contact.countryCode = DEFAULT_COUNTRY_CODE;
  }

  public findMunicipalitiesByCityCode() {
    this.municipalities = getMunicipalitiesByCityCode(this.$store, this.contact.cityCode);
  }

  private formatPhoneNumber(value) {
    if (!value) return value;
    value = value.replace(DEFAULT_COUNTRY_CODE, '');
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 9) {
      return `(${DEFAULT_COUNTRY_CODE}) ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 5)} ${phoneNumber.slice(5, 7)} ${phoneNumber.slice(7)}`;
    }
    return `(${DEFAULT_COUNTRY_CODE}) ${phoneNumber.slice(0, 3)} ${phoneNumber.slice(3, 5)} ${phoneNumber.slice(5, 7)} ${phoneNumber.slice(7, 9)}`;
  }

  public phoneNumberFormatter() {
    const formattedInputValue = this.formatPhoneNumber(this.contact.phone);
    this.contact.phone = formattedInputValue;
  }

  public faxNumberFormatter() {
    const formattedInputValue = this.formatPhoneNumber(this.contact.fax);
    this.contact.fax = formattedInputValue;
  }
}
