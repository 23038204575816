import { Component, Vue, Prop } from 'vue-property-decorator';
import AppNavbarContent from '@/core/app-navbar/app-navbar-content.vue';

@Component({
  components: {
    'app-navbar-content': AppNavbarContent,
  },
})
export default class AppDashboardNavbar extends Vue {
  @Prop({ default: 'default' /* default|light */ })
  readonly type: string;

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
}
