import { Component, Vue, Prop, Inject } from 'vue-property-decorator';
import Breadcrumb from '@/components/Breadcrumb/Breadcrumb.vue';
import BreadcrumbItem from '@/components/Breadcrumb/BreadcrumbItem.vue';
import { IBreadcrumbItem } from '@/shared/model/breadcrumbItem.model';
import Vue2Filters from 'vue2-filters';

@Component({
  components: {
    'breadcrumb': Breadcrumb,
    'breadcrumb-item': BreadcrumbItem,
  },
  mixins: [Vue2Filters.mixin],
})
export default class RouteBreadcrumb extends Vue {

  @Prop({ default: () => [] })
  readonly items!: IBreadcrumbItem[];

}