var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "form-group" }, [
        _c(
          "label",
          {
            attrs: { for: "address" },
            domProps: { textContent: _vm._s(_vm.$t("contact.address")) },
          },
          [_vm._v("Address")]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.contact.address,
              expression: "contact.address",
            },
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            name: "address",
            id: "address",
            "data-cy": "address",
            placeholder: _vm.$t("contact.fullAddress"),
          },
          domProps: { value: _vm.contact.address },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.$set(_vm.contact, "address", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "b-row",
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    attrs: { for: "city" },
                    domProps: { textContent: _vm._s(_vm.$t("contact.city")) },
                  },
                  [_vm._v("City")]
                ),
                _vm._v(" "),
                _c(
                  "b-form-select",
                  {
                    staticClass: "form-control",
                    class: {
                      valid: !_vm.$v.contact.cityCode.$invalid,
                      invalid: _vm.$v.contact.cityCode.$invalid,
                    },
                    attrs: { required: "", "data-cy": "city", id: "city" },
                    on: { change: _vm.findMunicipalitiesByCityCode },
                    model: {
                      value: _vm.contact.cityCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.contact, "cityCode", $$v)
                      },
                      expression: "contact.cityCode",
                    },
                  },
                  _vm._l(_vm.cities, function (city) {
                    return _c(
                      "option",
                      { key: city.id, domProps: { value: city.code } },
                      [_vm._v(_vm._s(city.name))]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("b-col", [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    attrs: { for: "municipality" },
                    domProps: {
                      textContent: _vm._s(_vm.$t("contact.municipality")),
                    },
                  },
                  [_vm._v("Municipality")]
                ),
                _vm._v(" "),
                _c(
                  "b-form-select",
                  {
                    staticClass: "form-control",
                    class: {
                      valid: !_vm.$v.contact.municipalityCode.$invalid,
                      invalid: _vm.$v.contact.municipalityCode.$invalid,
                    },
                    attrs: {
                      required: "",
                      "data-cy": "municipality",
                      id: "municipality",
                    },
                    model: {
                      value: _vm.contact.municipalityCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.contact, "municipalityCode", $$v)
                      },
                      expression: "contact.municipalityCode",
                    },
                  },
                  _vm._l(_vm.municipalities, function (municipality) {
                    return _c(
                      "option",
                      {
                        key: municipality.id,
                        domProps: { value: municipality.code },
                      },
                      [_vm._v(_vm._s(municipality.name))]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", [
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "zipCode" },
              domProps: { textContent: _vm._s(_vm.$t("contact.zipCode")) },
            },
            [_vm._v("Zip Code")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.contact.zipCode,
                expression: "contact.zipCode",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "zipCode",
              id: "zipCode",
              "data-cy": "zipCode",
              placeholder: _vm.$t("contact.zipCode"),
            },
            domProps: { value: _vm.contact.zipCode },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.contact, "zipCode", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.contact.countryCode,
                expression: "contact.countryCode",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "hidden",
              name: "countryCode",
              id: "countryCode",
              "data-cy": "countryCode",
            },
            domProps: { value: _vm.contact.countryCode },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.contact, "countryCode", $event.target.value)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "phone" },
              domProps: { textContent: _vm._s(_vm.$t("contact.phone")) },
            },
            [_vm._v("Phone")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.trim",
                value: _vm.contact.phone,
                expression: "contact.phone",
                modifiers: { trim: true },
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "phone",
              id: "phone",
              "data-cy": "phone",
              placeholder: _vm.$t("contact.phonePlaceholder"),
            },
            domProps: { value: _vm.contact.phone },
            on: {
              change: _vm.phoneNumberFormatter,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.contact, "phone", $event.target.value.trim())
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c(
            "label",
            {
              staticClass: "form-control-label",
              attrs: { for: "fax" },
              domProps: { textContent: _vm._s(_vm.$t("contact.fax")) },
            },
            [_vm._v("Fax")]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.contact.fax,
                expression: "contact.fax",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "text",
              name: "fax",
              id: "fax",
              "data-cy": "fax",
              placeholder: _vm.$t("contact.phonePlaceholder"),
            },
            domProps: { value: _vm.contact.fax },
            on: {
              change: _vm.faxNumberFormatter,
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.contact, "fax", $event.target.value)
              },
            },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }