var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { class: "footer bg-gradient-" + _vm.wrapperColor }, [
    _vm.authenticated && _vm.currentSchool !== null
      ? _c("div", { staticClass: "footer__school_address" }, [
          _c("span", { staticClass: "font-weight-bold" }, [
            _vm._v(_vm._s(_vm.userDefaultSchoolName)),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("font-awesome-icon", { attrs: { icon: "home" } }),
              _vm._v(" "),
              _vm.currentSchool.contact.address
                ? _c("span", [
                    _vm._v(
                      " " + _vm._s(_vm.currentSchool.contact.address) + " "
                    ),
                    _c("br"),
                  ])
                : _vm._e(),
              _vm._v(
                "\n      " +
                  _vm._s(_vm.currentSchool.contact.municipalityName) +
                  ", " +
                  _vm._s(_vm.currentSchool.contact.cityName) +
                  "\n    "
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "span",
              [
                _c("font-awesome-icon", { attrs: { icon: "at" } }),
                _vm._v(" " + _vm._s(_vm.currentSchool.email) + "\n      "),
              ],
              1
            ),
            _vm._v(" "),
            _vm.currentSchool.contact.phone
              ? _c(
                  "span",
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "phone-square" },
                    }),
                    _vm._v(
                      " " + _vm._s(_vm.currentSchool.contact.phone) + "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.currentSchool.contact.fax
              ? _c(
                  "span",
                  [
                    _c("font-awesome-icon", { attrs: { icon: "fax" } }),
                    _vm._v(
                      " " + _vm._s(_vm.currentSchool.contact.fax) + "\n      "
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer__copy_right justify-content-lg-between" },
      [
        _c("div", { staticClass: "copyright text-center footer__text" }, [
          _vm._v("\n      © " + _vm._s(_vm.year) + " "),
          _c(
            "a",
            {
              staticClass: "font-weight-bold ml-1 footer__text",
              attrs: { href: "/" },
            },
            [_vm._v("Web Scolaire")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }