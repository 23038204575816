var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("base-header", {
        class: "pb-6 pb-8 pt-5 pt-md-5 bg-gradient-" + _vm.wrapperColor,
      }),
      _vm._v(" "),
      _c(
        "b-container",
        { staticClass: "mt--7 content", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { lg: "12" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _vm.useBreadcrumb
                        ? _c("route-breadcrumb", {
                            attrs: { items: _vm.breadcrumbItems },
                          })
                        : _c("b-card-header", { staticClass: "border-0" }, [
                            _c("h2", { staticClass: "mb-0 font-weight-bold" }, [
                              _c("span", {
                                attrs: { id: _vm.nameUF + "-heading" },
                                domProps: { innerHTML: _vm._s(_vm.title) },
                              }),
                            ]),
                          ]),
                      _vm._v(" "),
                      _vm.showPageHeading
                        ? _c(
                            "h2",
                            {
                              attrs: {
                                id: "page-heading",
                                "data-cy": _vm.nameUF + "-Heading",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-end mr-md-2",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-info mr-2",
                                      attrs: { disabled: _vm.isFetching },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$emit("syncList")
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: {
                                          icon: "sync",
                                          spin: _vm.isFetching,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.refreshListLabel)),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.showCreateButton
                                    ? _c("router-link", {
                                        attrs: {
                                          to: { name: _vm.nameUF + "Create" },
                                          custom: "",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function (ref) {
                                                var navigate = ref.navigate
                                                return [
                                                  _c(
                                                    "button",
                                                    {
                                                      class:
                                                        "btn btn-primary jh-create-entity create-" +
                                                        _vm.nameLF,
                                                      attrs: {
                                                        id: "jh-create-entity",
                                                        "data-cy":
                                                          "entityCreateButton",
                                                      },
                                                      on: { click: navigate },
                                                    },
                                                    [
                                                      _c("font-awesome-icon", {
                                                        attrs: { icon: "plus" },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "global.add"
                                                              )
                                                            ),
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.createLabel
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          698683169
                                        ),
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("div", { staticClass: "p-2" }, [_vm._t("default")], 2),
                      _vm._v(" "),
                      _c("br"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }