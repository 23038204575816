// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.common with an alias.
import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import App from './app.vue';
import Vue2Filters from 'vue2-filters';
import { ToastPlugin } from 'bootstrap-vue';
import router from './router';
import * as config from './shared/config/config';
import * as bootstrapVueConfig from './shared/config/config-bootstrap-vue';
import JhiItemCountComponent from './shared/jhi-item-count.vue';
import JhiSortIndicatorComponent from './shared/sort/jhi-sort-indicator.vue';
import InfiniteLoading from 'vue-infinite-loading';
import HealthService from './admin/health/health.service';
import MetricsService from './admin/metrics/metrics.service';
import LogsService from './admin/logs/logs.service';
import ConfigurationService from '@/admin/configuration/configuration.service';
import ActivateService from './account/activate/activate.service';
import RegisterService from './account/register/register.service';
import UserManagementService from '@/admin/user-management/user-management.service';
import AccountService from './account/account.service';
import AlertService from './shared/alert/alert.service';

import '../content/scss/vendor.scss';
import 'vue-select/dist/vue-select.css';
import TranslationService from '@/locale/translation.service';

import UserOAuth2Service from '@/entities/user/user.oauth2.service';
import DashboardPlugin from '@/plugins/dashboard-plugin';
/* tslint:disable */

import RegionService from '@/admin/region/region.service';
import CityService from '@/admin/city/city.service';
import MunicipalityService from '@/admin/municipality/municipality.service';
import StudentService from '@/entities/student/student.service';
import StudentInfoService from '@/entities/classroom/student/student-info.service';
import TeacherService from '@/entities/teacher/teacher.service';
import SchoolService from '@/admin/school/school.service';
import ParentService from '@/entities/parent/parent.service';
import SubjectService from '@/admin/subject/subject.service';
import SchoolYearService from '@/admin/school-year/school-year.service';
import SchoolYearTermService from '@/entities/school-year-term/school-year-term.service';
import ClassroomOptionService from '@/admin/classroom-option/classroom-option.service';
import ClassroomService from '@/entities/classroom/classroom.service';
import SchoolYearClassroomService from '@/entities/school-year-classroom/school-year-classroom.service';
import DepartmentService from '@/admin/department/department.service';
import AssessmentTypeService from '@/entities/assessment-type/assessment-type.service';
import AssessmentService from '@/entities/assessment/assessment.service';
import AssessmentMarkService from '@/entities/assessment-mark/assessment-mark.service';
import vSelect from 'vue-select'
// jhipster-needle-add-entity-service-to-main-import - JHipster will import entities services here

/* tslint:enable */
Vue.config.productionTip = false;
config.initVueApp(Vue);
config.initFortAwesome(Vue);
bootstrapVueConfig.initBootstrapVue(Vue);
Vue.use(Vue2Filters);
Vue.use(ToastPlugin);
Vue.use(DashboardPlugin);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('jhi-item-count', JhiItemCountComponent);
Vue.component('jhi-sort-indicator', JhiSortIndicatorComponent);
Vue.component('infinite-loading', InfiniteLoading);
Vue.component('v-select', vSelect)
const i18n = config.initI18N(Vue);
const store = config.initVueXStore(Vue);

const translationService = new TranslationService(store, i18n);
const accountService = new AccountService(store, translationService, router);

router.beforeEach((to, from, next) => {
  if (!to.matched.length) {
    next('/');
  }
  if (to.meta && to.meta.authorities && to.meta.authorities.length > 0) {
    accountService.hasAnyAuthorityAndCheckAuth(to.meta.authorities).then(value => {
      if (!value) {
        sessionStorage.setItem('requested-url', to.fullPath);
        next('/');
      } else {
        next();
      }
    });
  } else {
    // no authorities, so just proceed
    next();
  }
});

/* tslint:disable */
new Vue({
  el: '#app',
  components: { App },
  template: '<App/>',
  router,
  provide: {
    activateService: () => new ActivateService(),
    registerService: () => new RegisterService(),
    userService: () => new UserManagementService(store),
    healthService: () => new HealthService(),
    configurationService: () => new ConfigurationService(),
    logsService: () => new LogsService(),
    metricsService: () => new MetricsService(),

    userOAuth2Service: () => new UserOAuth2Service(),
    translationService: () => translationService,
    regionService: () => new RegionService(store),
    cityService: () => new CityService(store),
    municipalityService: () => new MunicipalityService(store),
    studentService: () => new StudentService(),
    studentInfoService: () => new StudentInfoService(),
    teacherService: () => new TeacherService(),
    schoolService: () => new SchoolService(store),
    parentService: () => new ParentService(),
    subjectService: () => new SubjectService(),
    schoolYearService: () => new SchoolYearService(),
    schoolYearTermService: () => new SchoolYearTermService(),
    classroomOptionService: () => new ClassroomOptionService(),
    classroomService: () => new ClassroomService(),
    schoolYearClassroomService: () => new SchoolYearClassroomService(),
    departmentService: () => new DepartmentService(),
    assessmentTypeService: () => new AssessmentTypeService(),
    assessmentService: () => new AssessmentService(),
    assessmentMarkService: () => new AssessmentMarkService(),
    accountService: () => accountService,
    alertService: () => new AlertService(),
  },
  i18n,
  store,
});
