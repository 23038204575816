import { getWrapperColor } from '@/shared/helper';
import { Cycle } from '@/shared/model/enumerations/cycle.model';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class CyclesList extends Vue {

  @Prop({default: () => []})
  readonly cycles: Cycle[];

  public getCycleColor(cycle: Cycle): string {
    return getWrapperColor(cycle);
  }
  public get userDefaultCycle(): Cycle {
    return this.$store.getters.account?.preferences?.currentCycle;
  }
}