import Vuex from 'vuex';
import VueI18n, { DateTimeFormats } from 'vue-i18n';
import JhiFormatter from './formatter';
import { setupAxiosInterceptors } from '@/shared/config/axios-interceptor';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons/faAsterisk';
import { faBan } from '@fortawesome/free-solid-svg-icons/faBan';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { faBook } from '@fortawesome/free-solid-svg-icons/faBook';
import { faCloud } from '@fortawesome/free-solid-svg-icons/faCloud';
import { faCogs } from '@fortawesome/free-solid-svg-icons/faCogs';
import { faDatabase } from '@fortawesome/free-solid-svg-icons/faDatabase';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons/faEyeSlash';
import { faFlag } from '@fortawesome/free-solid-svg-icons/faFlag';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import { faHome } from '@fortawesome/free-solid-svg-icons/faHome';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faLockOpen } from '@fortawesome/free-solid-svg-icons/faLockOpen';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons/faPencilAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faRoad } from '@fortawesome/free-solid-svg-icons/faRoad';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons/faSignInAlt';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt';
import { faTasks } from '@fortawesome/free-solid-svg-icons/faTasks';
import { faThList } from '@fortawesome/free-solid-svg-icons/faThList';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons/faUserPlus';
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers';
import { faUsersCog } from '@fortawesome/free-solid-svg-icons/faUsersCog';
import { faWrench } from '@fortawesome/free-solid-svg-icons/faWrench';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt';
import { faBookReader } from '@fortawesome/free-solid-svg-icons/faBookReader';
import { faHdd } from '@fortawesome/free-solid-svg-icons/faHdd';
import { faCity } from '@fortawesome/free-solid-svg-icons/faCity';
import { faSchool } from '@fortawesome/free-solid-svg-icons/faSchool';
import { faShapes } from '@fortawesome/free-solid-svg-icons/faShapes';
import { faChalkboard } from '@fortawesome/free-solid-svg-icons/faChalkboard';
import { faChalkboardTeacher } from '@fortawesome/free-solid-svg-icons/faChalkboardTeacher';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faUserTag } from '@fortawesome/free-solid-svg-icons/faUserTag';
import { faCubes } from '@fortawesome/free-solid-svg-icons/faCubes';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faPenSquare } from '@fortawesome/free-solid-svg-icons/faPenSquare';
import { faSquareFull } from '@fortawesome/free-solid-svg-icons/faSquareFull';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft';
import { faVectorSquare } from '@fortawesome/free-solid-svg-icons/faVectorSquare';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faAdjust } from '@fortawesome/free-solid-svg-icons/faAdjust';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import { faPhoneSquare } from '@fortawesome/free-solid-svg-icons/faPhoneSquare';
import { faFax } from '@fortawesome/free-solid-svg-icons/faFax';
import { faAt } from '@fortawesome/free-solid-svg-icons/faAt';
import { faMale } from '@fortawesome/free-solid-svg-icons/faMale';
import { faFemale } from '@fortawesome/free-solid-svg-icons/faFemale';

import VueCookie from 'vue-cookie';
import Vuelidate from 'vuelidate';
import Vue2Filters from 'vue2-filters';

import * as filters from '@/shared/date/filters';
import { accountStore } from '@/shared/config/store/account-store';
import { translationStore } from '@/shared/config/store/translation-store';
import { schoolStore } from '@/shared/config/store/school-store';
import { utilsStore } from '@/shared/config/store/utils-store';
import VuexPersistence from 'vuex-persist';

const dateTimeFormats: DateTimeFormats = {
  fr: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    },
  },
  en: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: 'UTC',
    },
    medium: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      weekday: 'short',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: 'UTC',
    },
  },
};

export function initVueApp(vue) {
  vue.use(VueCookie);
  vue.use(Vuelidate);
  vue.use(Vue2Filters);
  setupAxiosInterceptors(
    () => console.log('Unauthorized!'),
    () => console.log('Server error!')
  );
  filters.initFilters();
}

export function initFortAwesome(vue) {
  library.add(
    faArrowLeft,
    faAsterisk,
    faBan,
    faBars,
    faBell,
    faBook,
    faCloud,
    faCogs,
    faDatabase,
    faEye,
    faEyeSlash,
    faFlag,
    faHeart,
    faHome,
    faList,
    faLock,
    faLockOpen,
    faPencilAlt,
    faPlus,
    faRoad,
    faSave,
    faSearch,
    faSignInAlt,
    faSignOutAlt,
    faSort,
    faSortDown,
    faSortUp,
    faSync,
    faTachometerAlt,
    faTasks,
    faThList,
    faTimes,
    faTimesCircle,
    faTrash,
    faUser,
    faUserPlus,
    faUsers,
    faUsersCog,
    faWrench,
    faCalendarAlt,
    faBookReader,
    faHdd,
    faCity,
    faSchool,
    faShapes,
    faChalkboard,
    faChalkboardTeacher,
    faLayerGroup,
    faUserTag,
    faCubes,
    faEdit,
    faPenSquare,
    faSquareFull,
    faAngleLeft,
    faVectorSquare,
    faClipboard,
    faAdjust,
    faFilter,
    faDoorOpen,
    faPhoneSquare,
    faFax,
    faAt,
    faMale,
    faFemale,
  );
}

export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({
    dateTimeFormats,
    silentTranslationWarn: true,
    formatter: new JhiFormatter(),
  });
}

const vuexLocal = new VuexPersistence<any>({
  storage: window.localStorage
})

export function initVueXStore(vue) {
  vue.use(Vuex);
  return new Vuex.Store({
    modules: {
      accountStore,
      translationStore,
      schoolStore,
      utilsStore,
    },
    plugins: [vuexLocal.plugin]
  });
}
