var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white",
      attrs: { id: "sidenav-main" },
    },
    [
      _c(
        "div",
        { staticClass: "container-fluid" },
        [
          _c("navbar-toggle-button", {
            nativeOn: {
              click: function ($event) {
                return _vm.showSidebar.apply(null, arguments)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "router-link",
            { staticClass: "navbar-brand", attrs: { to: "/" } },
            [
              _c("img", {
                staticClass: "navbar-brand-img",
                attrs: { src: _vm.logo, alt: "logo" },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "school-info" }, [
                _vm.userDefaultSchoolName
                  ? _c("div", { class: _vm.isReadOnly ? "text-danger" : "" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.userDefaultSchoolName) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm.userDefaultCycle
                    ? _c(
                        "span",
                        {
                          class: "font-weight-bold text-" + _vm.wrapperColor,
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t(
                                "webScolaireApp.cycle." + _vm.userDefaultCycle
                              )
                            ),
                          },
                        },
                        [_vm._v("cycle")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.userDefaultSchoolYearCode
                    ? _c(
                        "span",
                        {
                          class: _vm.userDefaultSchoolYearIsCurrent
                            ? "text-success"
                            : "text-danger",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.userDefaultSchoolYearCode) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("ul", { staticClass: "d-md-none" }, [_vm._t("mobile-right")], 2),
          _vm._v(" "),
          _vm._t("default"),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$sidebar.showSidebar,
                  expression: "$sidebar.showSidebar",
                },
              ],
              staticClass: "navbar-collapse collapse show",
              attrs: { id: "sidenav-collapse-main" },
            },
            [
              _c("div", { staticClass: "navbar-collapse-header d-md-none" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6 collapse-brand" },
                    [
                      _c("router-link", { attrs: { to: "/" } }, [
                        _c("img", { attrs: { src: _vm.logo } }),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 collapse-close" },
                    [
                      _c("navbar-toggle-button", {
                        nativeOn: {
                          click: function ($event) {
                            return _vm.closeSidebar.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._t("entities"),
              _vm._v(" "),
              _vm._t("admin"),
              _vm._v(" "),
              _vm._t("monitoring"),
            ],
            2
          ),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }