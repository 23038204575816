import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ISubject } from '@/shared/model/subject.model';
import { doGet, doSimpleGet, doDelete, doPost, doPut, getByCycle, getWithParams } from '@/shared/helper';
import { Cycle } from '@/shared/model/enumerations/cycle.model';

const baseApiUrl = 'api/admin/subjects';

export default class SubjectService {
  public find(id: string): Promise<ISubject> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return doSimpleGet(url + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public retrieveByCycle(schoolId?: any, cycle?: Cycle, search?: string, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return getWithParams(url, {cycle, search}, paginationQuery);
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: ISubject): Promise<ISubject> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: ISubject): Promise<ISubject> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
