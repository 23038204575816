import axios from 'axios';
import Component from 'vue-class-component';
import { Inject, Prop } from 'vue-property-decorator';
import { ZoomCenterTransition } from 'vue2-transitions';
import SchoolService from '@/admin/school/school.service';
import { UserType } from '@/shared/model/enumerations/user-type.model';
import AlertService from '@/shared/alert/alert.service';
import { redirectToSettings } from '@/shared/helper';
import AbstractWsEntity from '@/entities/AbstractWsEntity';
import CityService from '@/admin/city/city.service';
import MunicipalityService from '@/admin/municipality/municipality.service';
@Component({
  components: {
    'zoom-center-transition': ZoomCenterTransition,
  },
})
export default class LoginPage extends AbstractWsEntity {
  @Inject('alertService')
  private alertService: () => AlertService;

  @Inject('schoolService')
  private schoolService: () => SchoolService;

  @Inject('cityService')
  private cityService: () => CityService;

  @Inject('municipalityService')
  private municipalityService: () => MunicipalityService;

  public authenticationError = null;
  public authenticationErrorMessage = null;
  public login = null;
  public password = null;
  public rememberMe: boolean = null;
  public isSigningIn = false;

  @Prop({ default: 'black' })
  readonly type: string;
  public showMenu = false;
  public menuTransitionDuration = 250;
  public pageTransitionDuration = 200;
  public year = new Date().getFullYear();
  public pageClass = 'login-page';

  public get title(): string {
    return `${this.$route.name} Page`;
  }
  public toggleNavbar(): void {
    document.body.classList.toggle('nav-open');
    this.showMenu = !this.showMenu;
  }

  public closeMenu(): void {
    document.body.classList.remove('nav-open');
    this.showMenu = false;
  }

  public setBackgroundColor(): void {
    document.body.classList.add('bg-default');
  }

  public removeBackgroundColor(): void {
    document.body.classList.remove('bg-default');
  }

  public updateBackground(): void {
    if (!this.$route.meta.noBodyBackground) {
      this.setBackgroundColor();
    } else {
      this.removeBackgroundColor();
    }
  }
  public beforeDestroy() {
    this.removeBackgroundColor();
  }

  public beforeRouteUpdate(to, from, next) {
    // Close the mobile menu first then transition to next page
    if (this.showMenu) {
      this.closeMenu();
      setTimeout(() => {
        next();
      }, this.menuTransitionDuration);
    } else {
      next();
    }
  }

  public doLogin(): void {
    this.isSigningIn = true;
    const data = { username: this.login, password: this.password, rememberMe: this.rememberMe };
    axios
      .post('api/authenticate', data)
      .then(result => {
        const bearerToken = result.headers.authorization;
        if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
          const jwt = bearerToken.slice(7, bearerToken.length);
          if (this.rememberMe) {
            localStorage.setItem('jhi-authenticationToken', jwt);
            sessionStorage.removeItem('jhi-authenticationToken');
          } else {
            sessionStorage.setItem('jhi-authenticationToken', jwt);
            localStorage.removeItem('jhi-authenticationToken');
          }
        }
        this.authenticationError = false;

        this.accountService()
          .retrieveAccount()
          .then(res => {
            if (this.$store.getters.account) {
              if (this.$store.getters.account.userType !== UserType.ADMIN) {
                return this.schoolService().retrieveCurrentSchool(this.$store.getters.account.schoolId);
              } else if (this.$store.getters.account.preferences?.currentSchoolId) { // ADMIN
                return this.schoolService().retrieveCurrentSchool(this.$store.getters.account.preferences?.currentSchoolId);
              } else {
                redirectToSettings(this.$router);
                return;
              }
            }
            return res;
          })
          .then(() => this.cityService().retrieveAllCities())
          .then(() => this.municipalityService().retrieveAllMunicipalities());

        this.$router.push('/', () => {});
        this.isSigningIn = false;
      })
      .catch(err => {
        this.isSigningIn = false;
        this.authenticationError = true;
        this.authenticationErrorMessage = this.alertService().getHttpError(this, err.response, false);
      });
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
}
