var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "contact" }, [
      _c("a", { attrs: { href: "mailto:" + _vm.email } }, [
        _vm._v("\n      " + _vm._s(_vm.email) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _vm.phone
      ? _c("div", { staticClass: "contact" }, [
          _c(
            "a",
            { attrs: { href: "tel:" + _vm.phone } },
            [
              _c("font-awesome-icon", { attrs: { icon: "phone-square" } }),
              _vm._v(" " + _vm._s(_vm.phone) + "\n    "),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }