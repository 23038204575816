var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "wrapper" }, [
      _c(
        "div",
        [
          _c("notifications"),
          _vm._v(" "),
          _vm.authenticated && _vm.isTeacher
            ? _c(
                "side-bar",
                [
                  _c(
                    "template",
                    { slot: "mobile-right" },
                    [_c("app-navbar-content")],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isTeacher
                    ? _c(
                        "template",
                        { slot: "entities" },
                        [_c("entities-menu")],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isSchoolAdmin
                    ? _c("template", { slot: "admin" }, [_c("admin-menu")], 1)
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isAdmin
                    ? _c(
                        "template",
                        { slot: "monitoring" },
                        [_c("monitoring-menu")],
                        1
                      )
                    : _vm._e(),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "main-content" } },
            [
              _c("app-navbar"),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.$sidebar.displaySidebar(false)
                    },
                  },
                },
                [
                  _c(
                    "fade-transition",
                    {
                      attrs: {
                        duration: 200,
                        origin: "center top",
                        mode: "out-in",
                      },
                    },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.$route.meta.hideFooter ? _c("app-footer") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }