var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr", { staticClass: "my-3" }),
    _vm._v(" "),
    _c(
      "h6",
      { staticClass: "navbar-heading p-0 text-muted" },
      [
        _c("font-awesome-icon", { attrs: { icon: "users-cog" } }),
        _vm._v(" "),
        _c(
          "span",
          {
            domProps: { textContent: _vm._s(_vm.$t("global.menu.admin.main")) },
          },
          [_vm._v("Administration")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "navbar-nav" },
      [
        _vm.isAdmin
          ? _c("sidebar-item", {
              attrs: {
                link: {
                  name: _vm.$t("global.menu.admin.schools"),
                  path: "/admin/school",
                  classes: "text-primary",
                  faIcon: "school",
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.schoolYears"),
              path: "/admin/school-year",
              classes: "text-purple",
              faIcon: "calendar-alt",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.classroomOptions"),
              path: "/admin/classroom-option",
              classes: "text-green",
              faIcon: "cubes",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.subjects"),
              path: "/admin/subject",
              classes: "text-primary",
              faIcon: "book",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.userManagement"),
              path: "/admin/user-management",
              classes: "text-primary",
              faIcon: "users",
            },
          },
        }),
        _vm._v(" "),
        _vm.isAdmin
          ? _c("sidebar-item", {
              attrs: {
                link: {
                  name: _vm.$t("global.menu.admin.regions"),
                  path: "/admin/region",
                  classes: "text-primary",
                  faIcon: "vector-square",
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isAdmin
          ? _c("sidebar-item", {
              attrs: {
                link: {
                  name: _vm.$t("global.menu.admin.cities"),
                  path: "/admin/city",
                  classes: "text-primary",
                  faIcon: "city",
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.isAdmin
          ? _c("sidebar-item", {
              attrs: {
                link: {
                  name: _vm.$t("global.menu.admin.municipalities"),
                  path: "/admin/municipality",
                  classes: "text-primary",
                  faIcon: "shapes",
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }