var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "navbar-toggler",
      attrs: {
        type: "button",
        "data-toggle": "collapse",
        "data-target": _vm.target,
        "aria-controls": _vm.target,
        "aria-expanded": _vm.toggled,
        "aria-label": "Toggle navigation",
      },
    },
    [
      _c("span", { staticClass: "navbar-toggler-icon" }),
      _vm._v(" "),
      _vm._t("default", function () {
        return [_c("span")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }