import { IAudit } from './audit.model';
import { IContact } from './contact.model';
import { Cycle } from './enumerations/cycle.model';
import { Sex } from './enumerations/sex.model';
import { UserType } from './enumerations/user-type.model';
import { IPreferences } from './preferences.model';
import { IRegistration } from './registration.model';

export interface IUser extends IAudit {
  id?: any;
  login?: string;
  number?: string;
  schoolId?: string;
  schoolName?: string;
  firstName?: string;
  lastName?: string;
  sex?: Sex;
  dateOfBirth?: Date;
  userType?: UserType;
  email?: string;
  activated?: boolean;
  langKey?: string;
  schoolAdmin?: boolean;
  authorities?: any[];
  password?: string;
  contact?: IContact;
  cycles?: Cycle[];
  profileImage?: any;
  preferences?: IPreferences;
  registrations?: IRegistration[];
}

export class User implements IUser {
  constructor(
    public id?: any,
    public login?: string,
    public number?: string,
    public schoolId?: string,
    public schoolName?: string,
    public firstName?: string,
    public lastName?: string,
    public sex?: Sex,
    public dateOfBirth?: Date,
    public userType?: UserType,
    public email?: string,
    public activated?: boolean,
    public langKey?: string,
    public schoolAdmin?: boolean,
    public authorities?: any[],
    public password?: string,
    public contact?: IContact,
    public cycles?: Cycle[],
    public profileImage?: any,
    public preferences?: IPreferences,
    public registrations?: IRegistration[],
  ) {}
}

export interface IUserProfilePicture {
  image?: any;
  hasImage?: boolean;
  hasError?: boolean;
}

export class UserProfilePicture implements IUserProfilePicture {
  constructor(
    public image?: any,
    public hasImage?: boolean,
    public hasError?: boolean,
  ) {}
}
