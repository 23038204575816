import Vue from 'vue';
import { toast } from '../helper';
import get from 'lodash/get';

export default class AlertService {
  public showError(instance: Vue, message: string, params?: any, doToast?: boolean) {
    const alertMessage = instance.$t(message, params);
    if (doToast) {
      toast(instance, alertMessage, instance.$t('error.title'), 'danger');
    }
    return alertMessage;
  }

  public showHttpError(instance: Vue, httpErrorResponse: any) {
    return this.getHttpError(instance, httpErrorResponse, true);
  }

  public getHttpError(instance: Vue, httpErrorResponse: any, doToast: boolean) {
    switch (httpErrorResponse.status) {
      case 0:
        return this.showError(instance, 'error.server.not.reachable', '', doToast);

      case 400: {
        const arr = Object.keys(httpErrorResponse.headers);
        let errorHeader: string | null = null;
        let entityKey: string | null = null;
        for (const entry of arr) {
          if (entry.toLowerCase().endsWith('app-error')) {
            errorHeader = httpErrorResponse.headers[entry];
          } else if (entry.toLowerCase().endsWith('app-params')) {
            entityKey = httpErrorResponse.headers[entry];
          }
        }
        if (errorHeader) {
          const alertData = entityKey ? { entityName: instance.$t(`global.menu.entities.${entityKey}`) } : undefined;
          return this.showError(instance, errorHeader, alertData, doToast);
        }
        return this.showError(instance, this.handleErrors(instance, httpErrorResponse.data), '', doToast);
      }

      case 401:
        return this.showError(instance, this.handleAuthenticationErrors(httpErrorResponse.data), '', doToast);
      case 403:
        return this.showError(instance, 'error.http.403', '', doToast);
      case 404:
          return this.showError(instance, 'error.http.404', '', doToast);
      case 405:
          return this.showError(instance, 'error.http.405', '', doToast);
      case 500:
        return this.showError(instance, 'error.http.500', '', doToast);
      default:
        return this.showError(instance, this.handleErrors(instance, httpErrorResponse.data), '', doToast);
    }
  }

  public getErrorTranslation(instance: Vue, error: string): string {
    const messageKey = `error.${error}`;
    const translated = instance.$t(messageKey).toString();
    if (messageKey === translated) {
      return error;
    }
    return translated;
  }

  public handleErrors(instance: Vue, httpErrorResponseData: any) {
    if (get(httpErrorResponseData, ['properties', 'message'], '') !== '') {
      return httpErrorResponseData.properties.message;
    }
    const messageKey = `error.${httpErrorResponseData.message}`;
    const translated = instance.$t(messageKey).toString();
    if (messageKey === translated) {
      return httpErrorResponseData.message;
    }
    return translated;
  }

  public handleAuthenticationErrors(httpErrorResponseData: any) {
    if (get(httpErrorResponseData, 'message', '').indexOf('activated') !== -1) {
      return 'login.messages.error.deactivated';
    }
    if (get(httpErrorResponseData, 'message', '').indexOf('not found') !== -1) {
      return 'login.messages.error.authentication';
    }

    return httpErrorResponseData.message;
  }
}
