export interface IContact {
  address?: string;
  municipalityCode?: string;
  municipalityName?: string;
  cityCode?: string;
  cityName?: string;
  countryCode?: string,
  phone?: string;
  fax?: string;
  zipCode?: string;
}

export class Contact implements IContact {
  constructor(
    public address?: string,
    public municipalityCode?: string,
    public municipalityName?: string,
    public cityCode?: string,
    public cityName?: string,
    public countryCode?: string,
    public phone?: string,
    public fax?: string,
    public zipCode?: string
  ) {}
}
