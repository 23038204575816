var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c("div", { staticClass: "container py-5" }, [
      _c("div", { staticClass: "row" }, [
        !_vm.isSettings
          ? _c("div", { staticClass: "col-lg-4" }, [
              _c("div", { staticClass: "mb-4" }, [
                _c(
                  "div",
                  { staticClass: "card-body text-center" },
                  [
                    _c("img", {
                      staticClass: "rounded-circle img-fluid",
                      staticStyle: { width: "150px" },
                      attrs: { src: _vm.imageSrc },
                    }),
                    _vm._v(" "),
                    _c("h5", {
                      staticClass: "my-3",
                      domProps: { textContent: _vm._s(_vm.fullName) },
                    }),
                    _vm._v(" "),
                    _vm.showEditProfile
                      ? _c("router-link", {
                          attrs: {
                            to: { name: "ChangeProfilePicture" },
                            custom: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var navigate = ref.navigate
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pencil",
                                        attrs: { role: "button" },
                                        on: {
                                          click: navigate,
                                          keypress: function ($event) {
                                            if (
                                              !$event.type.indexOf("key") &&
                                              _vm._k(
                                                $event.keyCode,
                                                "enter",
                                                13,
                                                $event.key,
                                                "Enter"
                                              )
                                            ) {
                                              return null
                                            }
                                            return navigate.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: { icon: "edit" },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            4204750713
                          ),
                        })
                      : _vm._t("editPictureRouter"),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-4 mb-lg-0" }, [
                _c("div", { staticClass: "card-body p-0" }, [
                  _c(
                    "ul",
                    { staticClass: "list-group list-group-flush rounded-3" },
                    [_vm._t("menu")],
                    2
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "col-lg-8" }, [
          _c("div", { staticClass: "card mb-4" }, [
            _c("div", { staticClass: "card-body" }, [_vm._t("content")], 2),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }