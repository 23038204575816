var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr", { staticClass: "my-3" }),
    _vm._v(" "),
    _c(
      "h6",
      { staticClass: "navbar-heading p-0 text-muted" },
      [
        _c("font-awesome-icon", { attrs: { icon: "users-cog" } }),
        _vm._v(" "),
        _c(
          "span",
          {
            domProps: {
              textContent: _vm._s(_vm.$t("global.menu.admin.monitoring")),
            },
          },
          [_vm._v("Monitoring")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "navbar-nav" },
      [
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.metrics"),
              path: "/admin/metrics",
              classes: "text-orange",
              faIcon: "tachometer-alt",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.health"),
              path: "/admin/health",
              classes: "text-orange",
              faIcon: "heart",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.configuration"),
              path: "/admin/configuration",
              classes: "text-orange",
              faIcon: "cogs",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.admin.logs"),
              path: "/admin/logs",
              classes: "text-orange",
              faIcon: "tasks",
            },
          },
        }),
        _vm._v(" "),
        _vm.openAPIEnabled
          ? _c("sidebar-item", {
              attrs: {
                link: {
                  name: _vm.$t("global.menu.admin.apidocs"),
                  path: "/admin/docs",
                  classes: "text-orange",
                  faIcon: "book",
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }