import { Module } from 'vuex';

export const schoolStore: Module<any, any> = {
  state: {
    currentSchool: null,
  },
  getters: {
    currentSchool: state => state.currentSchool,
  },
  mutations: {
    currentSchool(state, school) {
      state.currentSchool = school;
    },
    logoutSchool(state) {
      state.currentSchool = null;
    },
  },
};
