import { Store } from 'vuex';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ISchool } from '@/shared/model/school.model';
import { CURRENT_SCHOOL } from '@/constants';
import { doGet, doSimpleGet, doDelete, doPost, doPut, buildParametersQuery, doPatch } from '@/shared/helper';

const baseApiUrl = 'api/admin/schools';

export default class SchoolService {
  constructor(private store: Store<any>) { }

  public find(id: string): Promise<ISchool> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieveCurrentSchool(schoolId: string): Promise<boolean> {
    return this.find(schoolId)
      .then(res => {
        this.store.commit('currentSchool', res);
        sessionStorage.setItem(CURRENT_SCHOOL, JSON.stringify(res));
        return true;
      })
      .catch(err => false);
  }

  public retrieve(search?: string, cycle?: string, cityCode?: string, municipalityCode?: string, paginationQuery?: any): Promise<any> {
    var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
    var params = buildParametersQuery({ search, cycle, cityCode, municipalityCode });
    var paramQueries = params ? `?${params}&` : '?';
    return doSimpleGet(baseApiUrl + paramQueries + paginationQueryOpts);
  }

  public retrieveAllActivated(): Promise<any> {
    return doSimpleGet(baseApiUrl + '/activated');
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: ISchool): Promise<ISchool> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: ISchool): Promise<ISchool> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }

  public patchUpdate(entity: ISchool): Promise<ISchool> {
    return doPatch(`${baseApiUrl}/${entity.id}`, entity);
  }
}
