import AccountService from '@/account/account.service';
import { getWrapperColor } from '@/shared/helper';
import { Cycle } from '@/shared/model/enumerations/cycle.model';
import { ISchool } from '@/shared/model/school.model';
import { Inject, Vue } from 'vue-property-decorator';

export default abstract class AbstractWsEntity extends Vue {
  @Inject('accountService') public accountService: () => AccountService;

  public hasAdminAuthority = false;
  public hasSchoolAuthority = false;
  public hasSubSchoolAuthority = false;
  public hasTeacherAuthority = false;
  public currentLanguage = '';
  
  public get isReadOnly(): boolean {
    return this.$store.getters.currentSchool?.activated === false;
  }

  public get userDefaultCycle(): Cycle {
    return this.$store.getters.account?.preferences?.currentCycle;
  }

  public get userDefaultSchoolYearCode(): string {
    return this.$store.getters.account?.preferences?.currentSchoolYearCode;
  }

  public get userDefaultSchoolId(): string {
    return this.$store.getters.account?.preferences?.currentSchoolId;
  }

  public get userDefaultSchoolName(): string {
    return this.$store.getters.account?.preferences?.currentSchoolName;
  }

  public get userDefaultSchoolAcronym(): string {
    return this.$store.getters.account?.preferences?.currentSchoolAcronym;
  }

  public get userDefaultSchoolYearId(): string {
    return this.$store.getters.account?.preferences?.currentSchoolYearId;
  }

  public get currentSchool(): ISchool {
    return this.$store.getters.currentSchool;
  }
 
  public get cycles() {
    return this.$store.getters.cycles;
  }

  public get currentSchoolCycles() {
    return this.$store.getters.currentSchool.cycles;
  }

  public previousState() {
    this.$router.go(-1);
  }

  public get isAdmin(): boolean {
    this.accountService()
      .isAdmin()
      .then(value => {
        this.hasAdminAuthority = value;
      });
    return this.hasAdminAuthority;
  }

  public get isSchoolAdmin(): boolean {
    this.accountService()
      .isSchoolAdmin()
      .then(value => {
        this.hasSchoolAuthority = value;
      });
    return this.hasSchoolAuthority;
  }

  public get isSchool(): boolean {
    this.accountService()
      .isSchool()
      .then(value => {
        this.hasSubSchoolAuthority = value;
      });
    return this.hasSubSchoolAuthority;
  }

  public get isTeacher(): boolean {
    this.accountService()
      .isTeacher()
      .then(value => {
        this.hasTeacherAuthority = value;
      });
    return this.hasTeacherAuthority;
  }

  public get wrapperColor(): string {
    return getWrapperColor(this.$store.getters.account?.preferences?.currentCycle);
  }

  public getCycleColor(cycle: Cycle): string {
    return getWrapperColor(cycle);
  }

  get locale() {
    return this.$store.getters.currentLanguage;
  } 

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
}