import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IClassroomOption } from '@/shared/model/classroom-option.model';
import { doDelete, doGet, doPost, doPut, doSimpleGet, getByCycle, getWithParams } from '@/shared/helper';
import { Cycle } from '@/shared/model/enumerations/cycle.model';

const baseApiUrl = 'api/admin/classroom-options';

export default class ClassroomOptionService {
  public find(id: string): Promise<IClassroomOption> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, search?: string, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
    var param = search ? `?search=${search}&` : '?';
    return doSimpleGet(url + param + paginationQueryOpts);
  }

  public retrieveByCycle(schoolId?: any, cycle?: Cycle, search?: string, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return getWithParams(url, {cycle, search}, paginationQuery);
  }
  
  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: IClassroomOption): Promise<IClassroomOption> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: IClassroomOption): Promise<IClassroomOption> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
