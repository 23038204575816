import { Module } from 'vuex';

export const utilsStore: Module<any, any> = {
  state: {
    municipalities: null,
    regions: null,
    cities: null,
    cycles: null,
  },
  getters: {
    municipalities: state => state.municipalities,
    regions: state => state.regions,
    cities: state => state.cities,
    cycles: state => state.cycles,
  },
  mutations: {
    municipalities(state, municipalities) {
      state.municipalities = municipalities;
    },
    regions(state, regions) {
      state.regions = regions;
    },
    cities(state, cities) {
      state.cities = cities;
    },
    setCycles(state, cycles) {
      state.cycles = cycles;
    },
    logoutUtils(state) {
      state.cities = null;
      state.regions = null;
      state.municipalities = null;
      state.cycles = null;
    },
  },
};
