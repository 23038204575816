import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ISchoolYearClassroom } from '@/shared/model/school-year-classroom.model';
import { doGet, doSimpleGet, doDelete, doPost, doPut } from '@/shared/helper';

const baseApiUrl = 'api/school-year-classrooms';

export default class SchoolYearClassroomService {
  public find(id: string): Promise<ISchoolYearClassroom> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return doSimpleGet(url + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: ISchoolYearClassroom): Promise<ISchoolYearClassroom> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: ISchoolYearClassroom): Promise<ISchoolYearClassroom> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
