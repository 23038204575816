import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IClassroom } from '@/shared/model/classroom.model';
import { Cycle } from '@/shared/model/enumerations/cycle.model';
import { buildParametersQuery, doDelete, doGet, doPost, doPut, doSimpleGet } from '@/shared/helper';
import getClassroomQuery from '@/shared/graphql/getClassroom.graphql';

const baseApiUrl = 'api/classrooms';

export default class ClassroomService {
  public find(id: string): Promise<IClassroom> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return doSimpleGet(url + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public retrieveByCycle(schoolId: string, cycle: Cycle, search?: string, classroomOption?: string, paginationQuery?: any): Promise<any> {
    var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
    var params = buildParametersQuery({ search, classroomOption });
    var paramQueries = params ? `?${params}&` : '?';
    const url = schoolId ? baseApiUrl + `/school/${schoolId}/cycle/${cycle}` : baseApiUrl;
    return doSimpleGet(url + paramQueries + paginationQueryOpts);
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: IClassroom): Promise<IClassroom> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: IClassroom): Promise<IClassroom> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }

  public async getClassroomGQL(classroomId: String, schoolYearId: String) {
    var query = getClassroomQuery
    return doPost("/graphql", {
      query,
      variables: { classroomId, schoolYearId },
    });
  }
}
