var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { attrs: { "aria-label": "breadcrumb" } },
    [
      _c(
        "b-breadcrumb",
        {
          class: [
            ((_obj = {}), (_obj["bg-" + _vm.type] = _vm.type), _obj),
            _vm.listClasses,
          ],
        },
        [_vm._t("default")],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }