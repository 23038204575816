import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IStudentInfo } from '@/shared/model/student-info.model';
import { doGet, doSimpleGet, doDelete, doPost, doPut } from '@/shared/helper';

const baseApiUrl = 'api/students-info';

export default class StudentInfoService {
  public find(id: string): Promise<IStudentInfo> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return doSimpleGet(url + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public retrieveByClassroom(classroomId?: any, paginationQuery?: any): Promise<any> {
    return doSimpleGet(baseApiUrl + `/classroom/${classroomId}` + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public retrieveByClassroomAndSchoolYear(classroomId?: any, schoolYearId?: any, paginationQuery?: any): Promise<any> {
    return doSimpleGet(baseApiUrl + `/classroom/${classroomId}/school-year/${schoolYearId}` + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public countClassroomsStudentsBySchoolYear(schoolYearId?: string): Promise<any> {
    return doSimpleGet(baseApiUrl + `/school-year/${schoolYearId}/count`);
  }


  public countClassroomStudentsBySchoolYear(classroomId?: string, schoolYearId?: string): Promise<any> {
    return doSimpleGet(baseApiUrl + `/classroom/${classroomId}/school-year/${schoolYearId}/count`);
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: IStudentInfo): Promise<IStudentInfo> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: IStudentInfo): Promise<IStudentInfo> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
