var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "jh-entity-details audit" },
    [
      _c("b-row", [
        _c(
          "span",
          {
            staticClass: "title",
            domProps: {
              textContent: _vm._s(_vm.$t("userManagement.createdBy")),
            },
          },
          [_vm._v("Created By")]
        ),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.entity.createdBy))]),
        _vm._v(",\n        "),
        _c("span", [
          _vm._v(
            _vm._s(_vm._f("formatDateTime")(_vm.entity.createdDate, _vm.locale))
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("b-row", [
        _c(
          "span",
          {
            staticClass: "title",
            domProps: {
              textContent: _vm._s(_vm.$t("userManagement.lastModifiedBy")),
            },
          },
          [_vm._v("Last Modified By")]
        ),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.entity.lastModifiedBy))]),
        _vm._v(",\n        "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm._f("formatDateTime")(_vm.entity.lastModifiedDate, _vm.locale)
            )
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }