var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      { staticClass: "navbar-nav" },
      [
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.entities.classroom"),
              path: "/classroom",
              classes: "text-green",
              faIcon: "chalkboard",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.entities.student"),
              path: "/student",
              classes: "text-primary",
              faIcon: "book-reader",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.entities.parent"),
              path: "/parent",
              classes: "text-primary",
              faIcon: "user-tag",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.entities.teacher"),
              path: "/teacher",
              classes: "text-primary",
              faIcon: "chalkboard-teacher",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.entities.assessment"),
              path: "/assessment",
              classes: "text-orange",
              faIcon: "edit",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.entities.assessmentType"),
              path: "/assessment-type",
              classes: "text-orange",
              faIcon: "adjust",
            },
          },
        }),
        _vm._v(" "),
        _c("sidebar-item", {
          attrs: {
            link: {
              name: _vm.$t("global.menu.entities.assessmentMark"),
              path: "/assessment-mark",
              classes: "text-orange",
              faIcon: "clipboard",
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }