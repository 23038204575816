import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IUser } from '@/shared/model/user.model';
import { doGet, doSimpleGet, doDelete, doPost, doPut, getWithParams } from '@/shared/helper';
import { Cycle } from '@/shared/model/enumerations/cycle.model';
import { UserType } from '@/shared/model/enumerations/user-type.model';

export default class UserService {
  constructor(private baseApiUrl: string) { }

  public find(id: string): Promise<IUser> {
    return doGet(`${this.baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, paginationQuery?: any): Promise<any> {
    const url = schoolId ? this.baseApiUrl + `/school/${schoolId}` : this.baseApiUrl;
    return doSimpleGet(url + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public retrieveByCycle(schoolId?: any, cycle?: Cycle, search?: string, paginationQuery?: any): Promise<any> {
    const url = schoolId ? this.baseApiUrl + `/school/${schoolId}` : this.baseApiUrl;
    return getWithParams(url, {cycle, search}, paginationQuery);
  }
  
  public delete(id: string): Promise<any> {
    return doDelete(`${this.baseApiUrl}/${id}`);
  }

  public create(entity: IUser): Promise<IUser> {
    return doPost(`${this.baseApiUrl}`, entity);
  }

  public update(entity: IUser): Promise<IUser> {
    return doPut(`${this.baseApiUrl}/${entity.id}`, entity);
  }

}
