import { buildAddress, getProfileImagePath } from '@/shared/helper';
import { User } from '@/shared/model/user.model';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class UserProfile extends Vue {
  @Prop({ default: () => new User() })
  readonly user: User;

  @Prop({ default: () => true })
  readonly showEditProfile: boolean;
  
  @Prop({ default: false })
  readonly isSettings: boolean;

  public get fullName(): string {
    return (this.user?.firstName ?? '') + ' ' + (this.user?.lastName ?? '');
  }

  public get fullAddress(): string {
    return buildAddress(this.user.contact);
  }

  public get imageSrc(): string {
    return getProfileImagePath(this.user.profileImage, this.user.sex);
  }
}
