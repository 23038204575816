var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "nav align-items-center" },
    [
      _vm.authenticated
        ? _c(
            "base-dropdown",
            {
              staticClass: "nav-item",
              attrs: { "menu-on-right": "", tag: "li", "title-tag": "a" },
            },
            [
              _c(
                "span",
                {
                  staticClass: "nav-link nav-link-icon",
                  attrs: {
                    slot: "title-container",
                    role: "button",
                    "aria-haspopup": "true",
                    "aria-expanded": "false",
                  },
                  slot: "title-container",
                },
                [_c("i", { staticClass: "ni ni-bell-55" })]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "dropdown-item" }, [
                _vm._v("Aucune notification"),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.languages && Object.keys(_vm.languages).length > 1
        ? _c(
            "base-dropdown",
            {
              staticClass: "nav-item",
              attrs: {
                "menu-on-right": "",
                tag: "li",
                "title-tag": "a",
                "title-classes": "nav-link pr-0",
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link pr-0",
                  attrs: { slot: "title-container", href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                    },
                  },
                  slot: "title-container",
                },
                [
                  _c(
                    "b-media",
                    {
                      staticClass: "align-items-center",
                      attrs: { "no-body": "" },
                    },
                    [
                      _c(
                        "span",
                        {},
                        [_c("font-awesome-icon", { attrs: { icon: "flag" } })],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-media-body",
                        { staticClass: "ml-2 d-none d-lg-block" },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "mb-0 text-sm",
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("global.menu.language")
                                ),
                              },
                            },
                            [_vm._v("Language")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.languages, function (value, key) {
                return _c(
                  "b-dropdown-item",
                  {
                    key: "lang-" + key,
                    class: { active: _vm.isActiveLanguage(key) },
                    on: {
                      click: function ($event) {
                        return _vm.changeLanguage(key)
                      },
                    },
                  },
                  [_vm._v("\n        " + _vm._s(value.name) + "\n      ")]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.authenticated
        ? _c(
            "base-dropdown",
            {
              staticClass: "nav-item",
              attrs: {
                "menu-on-right": "",
                tag: "li",
                "title-tag": "a",
                "title-classes": "nav-link pr-0",
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link pr-0",
                  attrs: { slot: "title-container", href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                    },
                  },
                  slot: "title-container",
                },
                [
                  _c(
                    "b-media",
                    {
                      staticClass: "align-items-center",
                      attrs: { "no-body": "" },
                    },
                    [
                      _c("span", {}, [
                        _c("img", {
                          staticClass: "rounded-circle img-fluid",
                          staticStyle: { width: "40px" },
                          attrs: { src: _vm.imageSrc, alt: "avatar" },
                        }),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-media-body",
                        { staticClass: "ml-2 d-none d-lg-block" },
                        [
                          _c(
                            "span",
                            { staticClass: "mb-0 text-sm font-weight-bold" },
                            [_vm._v(_vm._s(_vm.currentUserFullName))]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              [
                _c(
                  "b-dropdown-item",
                  {
                    class: _vm.wrapperColor,
                    attrs: {
                      "data-cy": "profile",
                      to: "/account/profile",
                      "active-class": "active",
                    },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "user" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.menu.account.profile")
                          ),
                        },
                      },
                      [_vm._v("Profile")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    class: _vm.wrapperColor,
                    attrs: {
                      "data-cy": "settings",
                      to: "/account/settings",
                      "active-class": "active",
                    },
                  },
                  [
                    _c("font-awesome-icon", { attrs: { icon: "cogs" } }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.menu.account.settings")
                          ),
                        },
                      },
                      [_vm._v("Settings")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "dropdown-divider" }),
                _vm._v(" "),
                _c(
                  "b-dropdown-item",
                  {
                    class: _vm.wrapperColor,
                    attrs: {
                      "data-cy": "logout",
                      id: "logout",
                      "active-class": "active",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.logout()
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: "sign-out-alt" },
                    }),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        domProps: {
                          textContent: _vm._s(
                            _vm.$t("global.menu.account.logout")
                          ),
                        },
                      },
                      [_vm._v("Sign out")]
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }