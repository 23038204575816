import { Component, Vue, Prop } from 'vue-property-decorator';


@Component
export default class Contact extends Vue {
  @Prop(String)
  readonly email: string;

  @Prop(String)
  readonly phone: string;
}