import { Component, Inject } from 'vue-property-decorator';
import TranslationService from '@/locale/translation.service';
import { doLogout, getProfileImagePath } from '@/shared/helper';
import AbstractWsEntity from '@/entities/AbstractWsEntity';

@Component
export default class AppNavbarContent extends AbstractWsEntity {
  @Inject('translationService') private translationService: () => TranslationService;
  
  public version = 'v' + VERSION;
  public activeNotifications = false;
  public showMenu = false;
  public searchModalVisible = false;
  public searchQuery = '';
  public languages: any = this.$store.getters.languages || [];

  created() {
    this.translationService().refreshTranslation(this.$store.getters.currentLanguage);
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some(path => {
      return this.$route.path.indexOf(path) === 0; // current path starts with this path string
    });
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): void {
    doLogout();
    this.$store.commit('logout');
    this.$store.commit('logoutUtils');
    this.$store.commit('logoutSchool');
    this.$router.push('login', () => {});
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public get openAPIEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('api-docs') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public get currentUserFullName(): string {
    return this.$store.getters.account.firstName + ' ' + this.$store.getters.account.lastName;
  }

  public get imageSrc(): string {
    if (this.authenticated) {
      const user = this.$store.getters.account;
      return getProfileImagePath(user.profileImage, user.sex);
    }
    return '';
  }
}
