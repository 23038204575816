var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      staticClass: "base-button",
      class: [
        { "rounded-circle": _vm.round },
        { "btn-wd": _vm.wide },
        { "btn-icon btn-fab": _vm.icon },
        { "btn-link": _vm.link },
        { disabled: _vm.disabled },
      ],
      attrs: {
        type: _vm.nativeType,
        disabled: _vm.disabled || _vm.loading,
        variant: !_vm.outline ? _vm.type : "outline-" + _vm.type,
        size: _vm.size,
        block: _vm.block,
      },
      on: { click: _vm.handleClick },
    },
    [
      _vm._t("loading", function () {
        return [
          _vm.loading
            ? _c("i", { staticClass: "fas fa-spinner fa-spin" })
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }