import axios from 'axios';
import { Store } from 'vuex';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IRegion } from '@/shared/model/region.model';
import { MAX_ITEMS } from '@/constants';
import { doDelete, doGet, doPost, doPut, doSimpleGet } from '@/shared/helper';

const baseApiUrl = 'api/admin/regions';

export default class RegionService {
  constructor(private store: Store<any>) {}

  public find(id: string): Promise<IRegion> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return doSimpleGet(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public retrieveAllRegions(): Promise<Boolean> {
    const paginationQuery = {
      size: MAX_ITEMS,
      sort: ['name,asc'],
    };
    return new Promise<Boolean>(resolve => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          this.store.commit('regions', res.data);
          resolve(true);
        })
        .catch(err => {
          resolve(false);
        });
    });
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: IRegion): Promise<IRegion> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: IRegion): Promise<IRegion> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
