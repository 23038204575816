<template>
  <div class="flex flex-col">
    <label class="leading-loose font-semibold">{{ label }}</label>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'select-box',
  props: {
    label: {
      type: String,
      default: ''
    }
  }
};
</script>