import { doGet } from '@/shared/helper';
import UserService from '../user/user.service';

export default class StudentService extends UserService {
  constructor() { 
    super('api/users/students');
  }

  public canUpdateStudent(id: string): Promise<Boolean> {
    return doGet(`api/users/students/canUpdate/${id}`);
  }
  
}
