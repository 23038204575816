import Vue from 'vue';
import dayjs from 'dayjs';
import { formatDate, formatDateFromNow, formatDateTime } from '../helper';

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_LONG_FORMAT = 'YYYY-MM-DDTHH:mm';

export function initFilters() {
  Vue.filter('formatDateTime', (value, locale) => {
    if (value) {
      return formatDateTime(value, locale);
    }
    return '';
  });
  Vue.filter('formatDate', (value, locale) => {
    if (value) {
     return formatDate(value, locale);
    }
    return '';
  });
  Vue.filter('formatDateFromNow', (value, locale) => {
    if (value) {
      return formatDateFromNow(value, locale);
    }
    return '';
  });
  Vue.filter('duration', value => {
    if (value) {
      const formatted = dayjs.duration(value).humanize();
      if (formatted) {
        return formatted;
      }
      return value;
    }
    return '';
  });
}
