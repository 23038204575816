var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._l(_vm.cycles, function (cycle) {
      return _c(
        "span",
        {
          key: cycle,
          staticClass: "mr-2",
          attrs: {
            value: cycle,
            label: _vm.$t("webScolaireApp.cycle." + cycle),
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "pt-2 badge",
              class:
                _vm.userDefaultCycle == cycle
                  ? "current_cycle badge-" + _vm.getCycleColor(cycle)
                  : "badge-" + _vm.getCycleColor(cycle),
            },
            [_vm._v(_vm._s(_vm.$t("webScolaireApp.cycle." + cycle)))]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }