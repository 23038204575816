import Vue from 'vue';
import { IContact } from '@/shared/model/contact.model';
import isString from 'lodash/isString';
import { CURRENT_SCHOOL, IMAGE_MAX_SIZE } from '@/constants';
import { Cycle } from './model/enumerations/cycle.model';
import axios from 'axios';
import buildPaginationQueryOpts from './sort/sorts';
import VueRouter from 'vue-router';
import filter from 'lodash/filter';
import { Sex } from './model/enumerations/sex.model';
import moment from 'moment';
import { IUserProfilePicture } from './model/user.model';

export function hasLength(str: string): boolean {
  return str && str !== '';
}

export function buildAddress(contact: IContact, phoneLabel: string = null, faxLabel: string = null): string {
  let fullAddress = '';
  if (contact) {
    if (hasLength(contact.address)) {
      fullAddress += contact.address + '<br>';
    }
    if (hasLength(contact.zipCode)) {
      fullAddress += contact.zipCode + ' ';
    }
    if (hasLength(contact.municipalityName)) {
      fullAddress += contact.municipalityName + ', ';
    }
    if (hasLength(contact.cityName)) {
      fullAddress += contact.cityName + '<br>';
    }
    if (hasLength(contact.phone) && phoneLabel) {
      fullAddress += phoneLabel + ' ' + contact.phone + '<br>';
    }
    if (hasLength(contact.fax) && faxLabel) {
      fullAddress += faxLabel + ' ' + contact.fax;
    }
  }
  return fullAddress;
}

export function tokenExists(): boolean {
  const token = localStorage.getItem('jhi-authenticationToken') || sessionStorage.getItem('jhi-authenticationToken');
  return isString(token);
}

export const loginValidator = (value: string) => {
  if (!value) {
    return true;
  }
  return /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/.test(value);
};

export function toast(instance: Vue, messageObj: any, headerTitleObj: any, type: string): void {
  instance.$root.$bvToast.toast(messageObj.toString(), {
    toaster: 'b-toaster-top-center',
    title: headerTitleObj.toString(),
    variant: type,
    solid: true,
    autoHideDelay: 5000,
  });
}

export const doLogout = () => {
  localStorage.removeItem('jhi-authenticationToken');
  sessionStorage.removeItem('jhi-authenticationToken');
  sessionStorage.removeItem(CURRENT_SCHOOL);
};

export function doGet(url?: string): Promise<any> {
  return doSimpleGet(url)
    .then(res => res.data);
}

export function doSimpleGet(url?: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios
      .get(url)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function getByCycle(url?: string, cycle?: Cycle, paginationQuery?: any): Promise<any> {
  var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
  var param = cycle ? `?cycle=${cycle}&` : '?';
  return doSimpleGet(url + param + paginationQueryOpts);
}

export function getWithParams(url?: string, parameters?: any, paginationQuery?: any): Promise<any> {
  var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
  var params = buildParametersQuery(parameters);
  var paramQueries = params ? `?${params}&` : '?';
  return doSimpleGet(url + paramQueries + paginationQueryOpts);
}

export function buildParametersQuery(params: {}): string {
  let query = [];
  Object.entries(params).forEach(entry => {
    const [key, value] = entry;
    query.push(`${key}=${value}`);
  })
  if (query.length != 0) {
    return query.join("&");
  }
  return '';
}

export function doPost(url?: string, entity?: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios
      .post(url, entity)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function doPut(url?: string, entity?: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios
      .put(url, entity)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function doPatch(url?: string, entity?: any): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios
      .patch(url, entity)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function doDelete(url?: string): Promise<any> {
  return new Promise<any>((resolve, reject) => {
    axios
      .delete(url)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });
}

export function redirectToSettings(router: VueRouter) {
  router.push('/account/settings');
}

export function getWrapperColor(cycle: Cycle): string {
  let defaultColor = 'success';
  if (cycle) {
    switch (cycle) {
      case Cycle.PRIMARY:
        return 'primary';
      case Cycle.SECONDARY:
        return 'dark';
      case Cycle.HIGH_SCHOOL:
        return 'info';
      case Cycle.UNIVERSITY:
        return 'success';
      case Cycle.PROFESSIONAL:
        return 'light';
      default:
        return defaultColor;
    }
  }
  return defaultColor;
}

export function getMunicipalitiesByCityCode(store?: any, cityCode?: string) {
  if (cityCode !== '') {
    return filter(store.getters.municipalities, { cityCode });
  }
  return [];
}

export function getProfileImagePath(profileImage: string, sex: Sex): string {
  if (profileImage != null) {
      return atob(profileImage);
  } else if (sex == Sex.MALE) {
      return 'content/images/no-image-male.png';
  } else if (sex == Sex.FEMALE) {
      return 'content/images/no-image-female.png';
  } else {
      return '';
  }
}

export function formatDateFromNow(dateStr: string, locale: string): string {
  moment.locale(locale);
  return moment.utc(dateStr).fromNow();
}

export function dateWithFormat(dateStr: string, locale: string, format: string): string {
  moment.locale(locale);
  return moment.utc(dateStr).format(format);
}

export function formatDate(dateStr: string, locale: string): string {
  return dateWithFormat(dateStr, locale, 'll');
}

export function formatDateTime(dateStr: string, locale: string): string {
  return dateWithFormat(dateStr, locale, 'lll');
}

export function selectImage(e: any, imageSize: number, profilePicture: IUserProfilePicture) {
  let reader = new FileReader();
  reader.readAsDataURL(e.target.files[0]);
  reader.onload = e => {
    const res: any = e.target.result;
    const img = new Image()
    img.src = res;
    img.onload = () => {
      const {
        height,
        width
      } = img;
      if (height > imageSize || width > imageSize) {
        profilePicture.image = false;
        profilePicture.hasImage = false;
        profilePicture.hasError = true;
      } else {
        profilePicture.image = e.target.result;
        profilePicture.hasImage = true;
        profilePicture.hasError = false;
      }
    };
  }
}
