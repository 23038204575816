import { Component, Vue, Prop } from 'vue-property-decorator';
import { upperFirst, lowerFirst }  from 'lodash';
import { IBreadcrumbItem } from '@/shared/model/breadcrumbItem.model';
import { getWrapperColor } from '@/shared/helper';
const PageProps = Vue.extend({
  props: {
    type: {
      type: String,
      default: 'default', // default|light
    },
  },
});

@Component
export default class PageWrapper extends PageProps {
  @Prop(String)
  readonly name: string;
  @Prop(String)
  readonly title: string;
  @Prop(String)
  readonly createLabel: string;
  @Prop(String)
  readonly refreshListLabel: string;
  @Prop(Boolean)
  readonly isFetching: boolean;
  @Prop({ default: true })
  readonly showPageHeading!: boolean;
  @Prop({ default: true })
  readonly showCreateButton!: boolean;
  @Prop({ default: () => [] })
  readonly breadcrumbItems!: IBreadcrumbItem[];
  @Prop({ default: true })
  readonly useBreadcrumb!: boolean;

  public get nameUF(): string {
    return upperFirst(this.name);
  }

  public get nameLF(): string {
    return lowerFirst(this.name);
  }

  public get wrapperColor(): string {
    return getWrapperColor(this.$store.getters.account?.preferences?.currentCycle);
  }
}
