var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "breadcrumb",
    { attrs: { "list-classes": "breadcrumb-links breadcrumb-dark" } },
    [
      _c(
        "breadcrumb-item",
        [
          _c(
            "router-link",
            { attrs: { to: { name: "Home" } } },
            [_c("font-awesome-icon", { attrs: { icon: "home" } })],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.items, function (item, index) {
        return _c(
          "breadcrumb-item",
          {
            key: item.name,
            staticStyle: { display: "inline-block" },
            attrs: { active: index === _vm.items.length - 1 },
          },
          [
            index < _vm.items.length - 1
              ? _c("router-link", { attrs: { to: { name: item.routeName } } }, [
                  _vm._v("\n      " + _vm._s(item.name) + "\n    "),
                ])
              : _c("span", { staticClass: "display-5 page-title" }, [
                  _vm._v(_vm._s(item.name)),
                ]),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }