import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import AppFooter from '@/core/app-footer/app-footer.vue';
import AppNavbar from '@/core/app-navbar/dashboard-navbar.vue';

import { FadeTransition } from 'vue2-transitions';
import EntitiesMenu from '@/core/app-sidebar/entities-menu.vue';
import AdminMenu from '@/core/app-sidebar/admin-menu.vue';
import MonitoringMenu from '@/core/app-sidebar/monitoring-menu.vue';
import AppNavbarContent from '@/core/app-navbar/app-navbar-content.vue';

import '@/shared/config/dayjs';
import { CURRENT_SCHOOL } from './constants';
import { tokenExists } from './shared/helper';
import LoginPage from '@/account/auth/login-page.vue';
import AbstractWsEntity from './entities/AbstractWsEntity';
import { Inject } from 'vue-property-decorator';
import CityService from './admin/city/city.service';
import MunicipalityService from './admin/municipality/municipality.service';

@Component({
  components: {
    ribbon: Ribbon,
    'app-navbar-content': AppNavbarContent,
    'app-navbar': AppNavbar,
    'app-footer': AppFooter,
    'fade-transition': FadeTransition,
    'entities-menu': EntitiesMenu,
    'admin-menu': AdminMenu,
    'monitoring-menu': MonitoringMenu,
    'login-page': LoginPage,
  },
})
export default class App extends AbstractWsEntity {

  @Inject('cityService')
  private cityService: () => CityService;

  @Inject('municipalityService')
  private municipalityService: () => MunicipalityService;

  created(): void {
    if (sessionStorage.getItem(CURRENT_SCHOOL)) {
      this.$store.commit('currentSchool', JSON.parse(sessionStorage.getItem(CURRENT_SCHOOL)));
    }

    if (!this.$store.getters.municipalities && this.authenticated) {
      this.municipalityService().retrieveAllMunicipalities().then();
    }

    if (!this.$store.getters.cities && this.authenticated) {
      this.cityService().retrieveAllCities().then();
    }

    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem(CURRENT_SCHOOL, JSON.stringify(this.$store.getters.currentSchool));
    });
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }
}
