import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { IAssessment } from '@/shared/model/assessment.model';
import { doGet, doSimpleGet, doDelete, doPost, doPut, getWithParams, buildParametersQuery } from '@/shared/helper';
import { Cycle } from '@/shared/model/enumerations/cycle.model';

const baseApiUrl = 'api/assessments';

export default class AssessmentService {
  public find(id: string): Promise<IAssessment> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, search?: string, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
    var param = search ? `?search=${search}&` : '?';
    return doSimpleGet(url + param + paginationQueryOpts);
  }

  public retrieveByCycle(schoolId: any, cycle: Cycle, classroomId?: string, teacherId?: string, subjectId?: string, search?: string, paginationQuery?: any): Promise<any> {
    var params = buildParametersQuery({ search, cycle, classroomId, teacherId, subjectId });
    var paramQueries = params ? `?${params}&` : '?';
    const url = schoolId ? baseApiUrl + `/school/${schoolId}/cycle/${cycle}` + paramQueries : baseApiUrl;
    return getWithParams(url, {search}, paginationQuery);
  }

  public retrieveByClassroom(classroomId?: any, search?: string, paginationQuery?: any): Promise<any> {
    var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
    return doSimpleGet(baseApiUrl + `/classroom/${classroomId}` + `?search=${search}&${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: IAssessment): Promise<IAssessment> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: IAssessment): Promise<IAssessment> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
