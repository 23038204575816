<template>
  <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-white" id="sidenav-main">
    <div class="container-fluid">
      <!--Toggler-->
      <navbar-toggle-button @click.native="showSidebar"> </navbar-toggle-button>
      <router-link class="navbar-brand" to="/">
        <img :src="logo" class="navbar-brand-img" alt="logo" />
        <div class="school-info">
          <div v-if="userDefaultSchoolName" :class="isReadOnly ? 'text-danger': ''">
            {{ userDefaultSchoolName }}
          </div>
          <div>
            <span v-if="userDefaultCycle" :class="'font-weight-bold text-' + wrapperColor" v-text="$t('webScolaireApp.cycle.' + userDefaultCycle)">cycle</span>
            <span v-if="userDefaultSchoolYearCode" :class="userDefaultSchoolYearIsCurrent ? 'text-success': 'text-danger'" >
              {{ userDefaultSchoolYearCode }}
            </span>
          </div>
        </div>
      </router-link>
      <ul class="d-md-none">
        <slot name="mobile-right"></slot>
      </ul>
      <slot></slot>
      <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <router-link to="/">
                <img :src="logo" />
              </router-link>
            </div>
            <div class="col-6 collapse-close">
              <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
            </div>
          </div>
        </div>

        <slot name="entities"> </slot>
        <slot name="admin"> </slot>
        <slot name="monitoring"> </slot>
      </div>
    </div>
  </nav>
</template>

<script>
import NavbarToggleButton from '@/components/NavbarToggleButton';
import { getWrapperColor } from '@/shared/helper';
export default {
  name: 'sidebar',
  components: {
    NavbarToggleButton,
  },
  props: {
    logo: {
      type: String,
      default: '../../../content/images/logo-ws.png',
      description: 'Sidebar app logo',
    },
    autoClose: {
      type: Boolean,
      default: true,
      description: 'Whether sidebar should autoclose on mobile when clicking an item',
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  methods: {
    closeSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    showSidebar() {
      this.$sidebar.displaySidebar(true);
    },
  },
  beforeDestroy() {
    if (this.$sidebar.showSidebar) {
      this.$sidebar.showSidebar = false;
    }
  },
  computed: {
    userDefaultCycle() {
      return this.$store.getters.account?.preferences?.currentCycle;
    },

    userDefaultSchoolYearIsCurrent() {
      return this.$store.getters.account?.preferences?.currentSchoolYear;
    },

    userDefaultSchoolYearCode() {
      return this.$store.getters.account?.preferences?.currentSchoolYearCode;
    },

    userDefaultSchoolName() {
      return this.$store.getters.account?.preferences?.currentSchoolName;
    },

    isReadOnly() {
      return this.$store.getters.currentSchool?.activated === false;
    },
    wrapperColor() {
      return getWrapperColor(this.$store.getters.account?.preferences?.currentCycle);
    },
  }
};
</script>
<style scoped lang="scss">
  .school-info {
    padding-top: 0.5rem;
    font-size: small;
    font-weight: 400;
  }
</style>
