var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "header",
      class: ((_obj = {}), (_obj["bg-" + _vm.type] = _vm.type), _obj),
    },
    [
      _c("b-container", { attrs: { fluid: "" } }, [
        _c("div", { staticClass: "header-body" }, [_vm._t("default")], 2),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }