import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ISchoolYear } from '@/shared/model/school-year.model';
import { doGet, doSimpleGet, doDelete, doPost, doPut, getByCycle } from '@/shared/helper';
import { Cycle } from '@/shared/model/enumerations/cycle.model';

const baseApiUrl = 'api/admin/school-years';

export default class SchoolYearService {
  public find(id: string): Promise<ISchoolYear> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return doSimpleGet(url + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public retrieveByCycle(schoolId?: any, cycle?: Cycle, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return getByCycle(url, cycle, paginationQuery);
  }
  
  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: ISchoolYear): Promise<ISchoolYear> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: ISchoolYear): Promise<ISchoolYear> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
