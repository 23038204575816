var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-nav",
    {
      staticClass: "navbar-top navbar-expand",
      class: { "navbar-dark": _vm.type === "default" },
      attrs: { "container-classes": "container-fluid" },
    },
    [
      _c(
        "a",
        {
          staticClass:
            "h4 mb-0 text-white text-uppercase d-none d-lg-inline-block active router-link-active",
          attrs: { href: "/", "aria-current": "page" },
        },
        [
          !_vm.authenticated
            ? _c("img", {
                staticClass: "logo",
                attrs: { src: "content/images/logo-ws.png" },
              })
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("b-navbar-nav", { staticClass: "align-items-center ml-md-auto" }, [
        _c("li", { staticClass: "nav-item d-sm-none" }, [
          _c(
            "a",
            {
              staticClass: "nav-link",
              attrs: {
                href: "#",
                "data-action": "search-show",
                "data-target": "#navbar-search-main",
              },
            },
            [_c("i", { staticClass: "ni ni-zoom-split-in" })]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "b-navbar-nav",
        { staticClass: "align-items-center ml-auto ml-md-0" },
        [_c("app-navbar-content")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }