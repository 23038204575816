import { formatDateTime } from '@/shared/helper';
import { Audit, IAudit } from '@/shared/model/audit.model';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { required } from "vuelidate/lib/validators";

const validations: any = {
  contact: {
    entity: {
      required,
    },
    locale: {
      required,
    }
  }
};
@Component({
  validations,
})
export default class EntityAudit extends Vue {
  @Prop()
  readonly entity;

  @Prop()
  readonly locale: string;
}