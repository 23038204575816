import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { Store } from 'vuex';
import { IUser } from '@/shared/model/user.model';
import { doDelete, doGet, doPost, doPut, doSimpleGet } from '@/shared/helper';

const baseApiUrl = 'api/admin/users';
export default class UserManagementService {
  constructor(private store: Store<any>) {}

  public get(userId: string, apiUrl = baseApiUrl): Promise<IUser> {
    return doGet(`${apiUrl}/${userId}`);
  }

  public create(user: IUser, apiUrl = baseApiUrl): Promise<IUser> {
    return doPost(`${apiUrl}`, user);
  }

  public update(user: IUser, apiUrl = baseApiUrl): Promise<IUser> {
    return doPut(apiUrl, user);
  }

  public remove(userId: string, apiUrl = baseApiUrl): Promise<IUser> {
    return doDelete(apiUrl + `/${userId}`);
  }

  public retrieve(paginationQuery?: any, search?: string, apiUrl = baseApiUrl): Promise<any> {
    var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
    var param = search ? `?search=${search}&` : '?';
    return doSimpleGet(apiUrl + param + paginationQueryOpts);
  }

  public retrieveAuthorities(): Promise<any> {
    return axios.get('api/admin/authorities');
  }
}
