var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-content bg-default" },
    [
      _c(
        "zoom-center-transition",
        { attrs: { duration: _vm.pageTransitionDuration, mode: "out-in" } },
        [
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass:
                    "header bg-gradient-success py-7 py-lg-8 pt-lg-9",
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "separator separator-bottom separator-skew zindex-100",
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            x: "0",
                            y: "0",
                            viewBox: "0 0 2560 100",
                            preserveAspectRatio: "none",
                            version: "1.1",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("polygon", {
                            staticClass: "fill-default",
                            attrs: { points: "2560 0 2560 100 0 100" },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-container",
                { staticClass: "mt--8 pb-5" },
                [
                  _c(
                    "b-row",
                    { staticClass: "justify-content-center" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-md-8" },
                        [
                          _vm.authenticationError
                            ? _c(
                                "b-alert",
                                {
                                  attrs: {
                                    show: "",
                                    "data-cy": "loginError",
                                    variant: "danger",
                                  },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$t(_vm.authenticationErrorMessage)
                                    ),
                                  },
                                },
                                [
                                  _c("strong", [_vm._v("Failed to sign in!")]),
                                  _vm._v(
                                    " Please check your credentials and try again.\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        { attrs: { lg: "5", md: "7" } },
                        [
                          _c(
                            "b-card",
                            {
                              staticClass: "bg-secondary border-0 mb-0",
                              attrs: { "no-body": "" },
                            },
                            [
                              _c(
                                "b-card-body",
                                { staticClass: "px-lg-5 py-lg-5" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-center text-muted mb-4",
                                    },
                                    [
                                      _c(
                                        "small",
                                        {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.$t("login.title")
                                            ),
                                          },
                                        },
                                        [_vm._v("Sign in with credentials")]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form",
                                    {
                                      attrs: { role: "form" },
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.doLogin()
                                        },
                                      },
                                    },
                                    [
                                      _c("base-input", {
                                        staticClass: "mb-3",
                                        attrs: {
                                          alternative: "",
                                          type: "text",
                                          name: "username",
                                          autofocus: "",
                                          "prepend-icon": "ni ni-email-83",
                                          placeholder: _vm.$t(
                                            "global.form['username.placeholder']"
                                          ),
                                        },
                                        model: {
                                          value: _vm.login,
                                          callback: function ($$v) {
                                            _vm.login = $$v
                                          },
                                          expression: "login",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("base-input", {
                                        staticClass: "mb-3",
                                        attrs: {
                                          alternative: "",
                                          name: "password",
                                          placeholder: _vm.$t(
                                            "login.form['password.placeholder']"
                                          ),
                                          "prepend-icon":
                                            "ni ni-lock-circle-open",
                                          type: "password",
                                          "data-cy": "password",
                                          isPasswordInput: "",
                                        },
                                        model: {
                                          value: _vm.password,
                                          callback: function ($$v) {
                                            _vm.password = $$v
                                          },
                                          expression: "password",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          attrs: {
                                            id: "rememberMe",
                                            name: "rememberMe",
                                            checked: "",
                                          },
                                          model: {
                                            value: _vm.rememberMe,
                                            callback: function ($$v) {
                                              _vm.rememberMe = $$v
                                            },
                                            expression: "rememberMe",
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.$t(
                                                    "login.form.rememberme"
                                                  )
                                                ),
                                              },
                                            },
                                            [_vm._v("Remember me")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "text-center" },
                                        [
                                          _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-primary my-4",
                                              attrs: {
                                                type: "submit",
                                                id: "signIn",
                                                "data-cy": "signIn",
                                                disabled: _vm.isSigningIn,
                                              },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "login.form.button"
                                                      )
                                                    ),
                                                  },
                                                },
                                                [_vm._v("Sign in")]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            { staticClass: "mt-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c(
                                    "b-link",
                                    {
                                      staticClass: "text-light text-sm",
                                      attrs: {
                                        to: "/account/reset/request",
                                        "data-cy": "forgetYourPasswordSelector",
                                      },
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("login.password.forgot")
                                        ),
                                      },
                                    },
                                    [_c("small", [_vm._v("Forgot password?")])]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }