import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ISchoolYearTerm } from '@/shared/model/school-year-term.model';
import { doGet, doSimpleGet, doDelete, doPost, doPut } from '@/shared/helper';

const baseApiUrl = 'api/school-year-terms';

export default class SchoolYearTermService {
  public find(id: string): Promise<ISchoolYearTerm> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(schoolId?: any, paginationQuery?: any): Promise<any> {
    const url = schoolId ? baseApiUrl + `/school/${schoolId}` : baseApiUrl;
    return doSimpleGet(url + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: ISchoolYearTerm): Promise<ISchoolYearTerm> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: ISchoolYearTerm): Promise<ISchoolYearTerm> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
