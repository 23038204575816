import { Authority } from '@/shared/security/authority';
const Student = () => import('@/entities/student/student.vue');
const StudentUpdate = () => import('@/entities/student/student-update.vue');
const StudentDetails = () => import('@/entities/student/student-details.vue');
const StudentInfo = () => import('@/entities/classroom/student/student-info.vue');
const StudentInfoUpdate = () => import('@/entities/classroom/student/student-info-update.vue');
const StudentInfoDetails = () => import('@/entities/classroom/student/student-info-details.vue');
const StudentPicture = () => import('@/entities/student/student-picture.vue');
const Teacher = () => import('@/entities/teacher/teacher.vue');
const TeacherUpdate = () => import('@/entities/teacher/teacher-update.vue');
const TeacherDetails = () => import('@/entities/teacher/teacher-details.vue');
const TeacherPicture = () => import('@/entities/teacher/teacher-picture.vue');
const Parent = () => import('@/entities/parent/parent.vue');
const ParentUpdate = () => import('@/entities/parent/parent-update.vue');
const ParentDetails = () => import('@/entities/parent/parent-details.vue');
const ParentPicture = () => import('@/entities/parent/parent-picture.vue');
const SchoolYearTerm = () => import('@/entities/school-year-term/school-year-term.vue');
const SchoolYearTermUpdate = () => import('@/entities/school-year-term/school-year-term-update.vue');
const SchoolYearTermDetails = () => import('@/entities/school-year-term/school-year-term-details.vue');
const SchoolYearClassroom = () => import('@/entities/school-year-classroom/school-year-classroom.vue');
const SchoolYearClassroomUpdate = () => import('@/entities/school-year-classroom/school-year-classroom-update.vue');
const SchoolYearClassroomDetails = () => import('@/entities/school-year-classroom/school-year-classroom-details.vue');
const Classroom = () => import('@/entities/classroom/classroom.vue');
const ClassroomUpdate = () => import('@/entities/classroom/classroom-update.vue');
const ClassroomDetails = () => import('@/entities/classroom/classroom-details.vue');
const AssessmentType = () => import('@/entities/assessment-type/assessment-type.vue');
const AssessmentTypeUpdate = () => import('@/entities/assessment-type/assessment-type-update.vue');
const AssessmentTypeDetails = () => import('@/entities/assessment-type/assessment-type-details.vue');
const Assessment = () => import('@/entities/assessment/assessment.vue');
const AssessmentUpdate = () => import('@/entities/assessment/assessment-update.vue');
const AssessmentDetails = () => import('@/entities/assessment/assessment-details.vue');
const AssessmentMark = () => import('@/entities/assessment-mark/assessment-mark.vue');
const AssessmentMarkUpdate = () => import('@/entities/assessment-mark/assessment-mark-update.vue');
const AssessmentMarkDetails = () => import('@/entities/assessment-mark/assessment-mark-details.vue');

export default [
  {
    path: '/student',
    name: 'Student',
    component: Student,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/student/new',
    name: 'StudentCreate',
    component: StudentUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/student/:studentId/edit',
    name: 'StudentEdit',
    component: StudentUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/student/:studentId/view',
    name: 'StudentView',
    component: StudentDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/student/:studentId/picture',
    name: 'ChangeStudentPicture',
    component: StudentPicture,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom/:classroomId/students',
    name: 'ClassroomStudentInfo',
    component: StudentInfo,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom/:classroomId/students/new',
    name: 'ClassroomStudentInfoCreate',
    component: StudentInfoUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom/:classroomId/students/:studentInfoId/edit',
    name: 'ClassroomStudentInfoEdit',
    component: StudentInfoUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom/:classroomId/students/:studentInfoId/view',
    name: 'ClassroomStudentInfoView',
    component: StudentInfoDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/teacher',
    name: 'Teacher',
    component: Teacher,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/teacher/new',
    name: 'TeacherCreate',
    component: TeacherUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/teacher/:teacherId/edit',
    name: 'TeacherEdit',
    component: TeacherUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/teacher/:teacherId/view',
    name: 'TeacherView',
    component: TeacherDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/teacher/:teacherId/picture',
    name: 'ChangeTeacherPicture',
    component: TeacherPicture,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/parent',
    name: 'Parent',
    component: Parent,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/parent/new',
    name: 'ParentCreate',
    component: ParentUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/parent/:parentId/edit',
    name: 'ParentEdit',
    component: ParentUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/parent/:parentId/view',
    name: 'ParentView',
    component: ParentDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/parent/:parentId/picture',
    name: 'ChangeParentPicture',
    component: ParentPicture,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-term',
    name: 'SchoolYearTerm',
    component: SchoolYearTerm,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-term/new',
    name: 'SchoolYearTermCreate',
    component: SchoolYearTermUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-term/:schoolYearTermId/edit',
    name: 'SchoolYearTermEdit',
    component: SchoolYearTermUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-term/:schoolYearTermId/view',
    name: 'SchoolYearTermView',
    component: SchoolYearTermDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-classroom',
    name: 'SchoolYearClassroom',
    component: SchoolYearClassroom,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-classroom/new',
    name: 'SchoolYearClassroomCreate',
    component: SchoolYearClassroomUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-classroom/:schoolYearClassroomId/edit',
    name: 'SchoolYearClassroomEdit',
    component: SchoolYearClassroomUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/school-year-classroom/:schoolYearClassroomId/view',
    name: 'SchoolYearClassroomView',
    component: SchoolYearClassroomDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom',
    name: 'Classroom',
    component: Classroom,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom/new',
    name: 'ClassroomCreate',
    component: ClassroomUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom/:classroomId/edit',
    name: 'ClassroomEdit',
    component: ClassroomUpdate,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/classroom/:classroomId/view',
    name: 'ClassroomView',
    component: ClassroomDetails,
    meta: { authorities: [Authority.SCHOOL] },
  },
  {
    path: '/assessment-type',
    name: 'AssessmentType',
    component: AssessmentType,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment-type/new',
    name: 'AssessmentTypeCreate',
    component: AssessmentTypeUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment-type/:assessmentTypeId/edit',
    name: 'AssessmentTypeEdit',
    component: AssessmentTypeUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment-type/:assessmentTypeId/view',
    name: 'AssessmentTypeView',
    component: AssessmentTypeDetails,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: Assessment,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment/new',
    name: 'AssessmentCreate',
    component: AssessmentUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment/:assessmentId/edit',
    name: 'AssessmentEdit',
    component: AssessmentUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment/:assessmentId/view',
    name: 'AssessmentView',
    component: AssessmentDetails,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/classroom/:classroomId/assessment/new',
    name: 'ClassroomAssessmentCreate',
    component: AssessmentUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/classroom/:classroomId/assessment/:assessmentId/edit',
    name: 'ClassroomAssessmentEdit',
    component: AssessmentUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/classroom/:classroomId/assessment',
    name: 'ClassroomAssessment',
    component: Assessment,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/classroom/:classroomId/assessment/:assessmentId/view',
    name: 'ClassroomAssessmentView',
    component: AssessmentDetails,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment-mark',
    name: 'AssessmentMark',
    component: AssessmentMark,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment-mark/new',
    name: 'AssessmentMarkCreate',
    component: AssessmentMarkUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment-mark/:assessmentMarkId/edit',
    name: 'AssessmentMarkEdit',
    component: AssessmentMarkUpdate,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
  {
    path: '/assessment-mark/:assessmentMarkId/view',
    name: 'AssessmentMarkView',
    component: AssessmentMarkDetails,
    meta: { authorities: [Authority.SCHOOL, Authority.TEACHER] },
  },
];
