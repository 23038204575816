import axios from 'axios';
import { Store } from 'vuex';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IMunicipality } from '@/shared/model/municipality.model';
import filter from 'lodash/filter';
import { MAX_ITEMS } from '@/constants';
import { doDelete, doGet, doPost, doPut, doSimpleGet } from '@/shared/helper';

const baseApiUrl = 'api/admin/municipalities';

export default class MunicipalityService {
  constructor(private store: Store<any>) {}

  public find(id: string): Promise<IMunicipality> {
    return doGet(`${baseApiUrl}/${id}`);
  }

  public retrieve(search?: string, paginationQuery?: any): Promise<any> {
    var paginationQueryOpts = buildPaginationQueryOpts(paginationQuery);
    var param = search ? `?search=${search}&` : '?';
    return doSimpleGet(baseApiUrl + param + paginationQueryOpts);
  }

  public retrieveAllMunicipalities(): Promise<boolean> {
    const paginationQuery = {
      size: MAX_ITEMS,
      sort: ['name,asc'],
    };
    return new Promise<boolean>(resolve => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then(res => {
          this.store.commit('municipalities', res.data);
          resolve(true);
        })
        .catch(err => {
          resolve(false);
        });
    });
  }

  public retrieveAllMunicipalitiesByCityCode(cityCode?: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.store.getters.municipalities) {
        resolve(filter(this.store.getters.municipalities, { cityCode: cityCode }));
      } else {
        const paginationQuery = {
          size: MAX_ITEMS,
          sort: ['name,asc'],
        };
        axios
          .get(baseApiUrl + `/cityCode/${cityCode}?${buildPaginationQueryOpts(paginationQuery)}`)
          .then(res => {
            resolve(res);
          })
          .catch(err => {
            reject(err);
          });
      }
    });
  }

  public delete(id: string): Promise<any> {
    return doDelete(`${baseApiUrl}/${id}`);
  }

  public create(entity: IMunicipality): Promise<IMunicipality> {
    return doPost(`${baseApiUrl}`, entity);
  }

  public update(entity: IMunicipality): Promise<IMunicipality> {
    return doPut(`${baseApiUrl}/${entity.id}`, entity);
  }
}
